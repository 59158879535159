import { IAction } from '../../interfaces';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initialState } from './initialState';
import {
    UPDATE_NOTIFICATION_SETTINGS,
    UPDATE_NOTIFICATION_SETTINGS_LOADING,
    UPDATE_SINGLE_NOTIFICATION_SETTING,
} from './action-types';
import { NotificationSettingsReducer } from './types';

const notificationSettingsPersistConfig = {
    key: 'notificationSettingsState',
    storage: storage,
    whitelist: [],
};

const notificationSettings = (
    state = initialState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { payload, type }: IAction<any>
): NotificationSettingsReducer => {
    switch (type) {
        case UPDATE_NOTIFICATION_SETTINGS_LOADING: {
            return {
                ...state,
                loading: payload.loading,
            };
        }
        case UPDATE_NOTIFICATION_SETTINGS: {
            return {
                ...state,
                notificationSettings: payload.notificationSettings,
                loading: false,
            };
        }
        case UPDATE_SINGLE_NOTIFICATION_SETTING: {
            if (state.notificationSettings == null) {
                return state;
            }
            const newNotificationTypes = state.notificationSettings.user_notification_types.map(
                setting => {
                    if (setting.notification_type_name === payload.notificationTypeName) {
                        return {
                            notification_type_name: payload.notificationTypeName,
                            is_subscribed: payload.isSubscribed,
                        };
                    }
                    return setting;
                }
            );
            return {
                ...state,
                notificationSettings: {
                    ...state.notificationSettings,
                    user_notification_types: newNotificationTypes,
                },
            };
        }
        default:
            return state;
    }
};

export const notificationSettingsReducer = persistReducer<NotificationSettingsReducer>(
    notificationSettingsPersistConfig,
    notificationSettings
);
