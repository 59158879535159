import React, { FC } from 'react';
import { Footer, Loader } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { UserAccounts, UserConnectionStatus } from 'src/store';

import './AuthedPage.scss';
import { ConnectButton } from 'thirdweb/react';
import { getSupportedWallets, getThirdWebClient } from 'src/helpers';

interface IPropsBaseAuth {
    children: React.ReactNode;
    fallbackComponent?: React.ReactNode;
    additionalAuthorizeFunction?: undefined;
    additionalAuthorizationFallback?: undefined;
}

interface IPropsAdditionalAuthorization {
    children: React.ReactNode;
    fallbackComponent?: React.ReactNode;
    additionalAuthorizeFunction: (user?: UserAccounts) => boolean;
    additionalAuthorizationFallback: React.ReactNode;
}

type tAuthedPageProps = IPropsBaseAuth | IPropsAdditionalAuthorization;

const isUserLoggedIn = (user?: UserAccounts) => {
    return user != null;
};

// When we want to gate a page behind some kind of authentication, we need to ensure
// that both Thirdweb and Balcony's logins have finished. Until that time, we want
// to show the user a loading spinner if the page is waiting for updates, or a
// Thirdweb connect button to prompt them to log in.
export const AuthedPage: FC<tAuthedPageProps> = ({
    children,
    fallbackComponent,
    additionalAuthorizeFunction,
    additionalAuthorizationFallback,
}) => {
    const { user, autoconnectStatus, balconyConnectStatus } = useAppSelector(
        state => state.userReducer
    );

    // If a page has additional authentication, run it after confirming a user is logged in.
    // If a user fails the additional authorization function, present the provided
    // fallback component.
    if (isUserLoggedIn(user)) {
        if (additionalAuthorizeFunction != null) {
            if (additionalAuthorizeFunction(user)) {
                return <>{children}</>;
            }
            return <>{additionalAuthorizationFallback}</>;
        } else {
            return <>{children}</>;
        }
    }

    const connectButton = (
        <ConnectButton
            client={getThirdWebClient()}
            theme={'light'}
            wallets={getSupportedWallets()}
            connectButton={{ label: 'Sign in' }}
            connectModal={{
                showThirdwebBranding: false,
                title: 'Log in to Balcony',
            }}
        />
    );

    const signInContent = (
        <>
            <h1>Please sign in to view this page</h1>
            {connectButton}
        </>
    );

    const loadingContent = <Loader />;

    const pageIsLoading =
        autoconnectStatus === UserConnectionStatus.Started ||
        autoconnectStatus == UserConnectionStatus.Unstarted ||
        balconyConnectStatus == UserConnectionStatus.Started;

    if (!pageIsLoading && fallbackComponent != null) {
        return <>{fallbackComponent}</>;
    }

    const pageContent = pageIsLoading ? loadingContent : signInContent;

    return (
        <div className="sign-in-page">
            <div className="sign-in-page__content">{pageContent}</div>
            <Footer />
        </div>
    );
};
