import logo from 'src/assets/images/balcony-purple.svg';

import './Footer.scss';

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__content container">
                <img height="40px" src={logo} alt="logo" />
            </div>
        </div>
    );
};
