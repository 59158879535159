import { IAction } from '../../interfaces';
import { initialState } from './initialState';
import {
    CLEAR_PROPERTY_DETAILS,
    GET_PROPERTY_DETAILS,
    SET_PROPERTY_DETAILS_LOADING,
} from './action-types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { tHouseAction } from './types';

const propertyDetailsPersistConfig = {
    key: 'propertyDetails',
    storage: storage,
    whitelist: [],
};

const propertyDetails = (state = initialState, action: IAction<tHouseAction>) => {
    switch (action.type) {
        case CLEAR_PROPERTY_DETAILS: {
            return initialState;
        }
        case GET_PROPERTY_DETAILS: {
            const attachments =
                action.payload?.data?.attachments && action.payload?.data?.attachments.length > 0
                    ? action.payload?.data?.attachments.filter(el => el.key === 'public')
                    : [];
            return {
                ...state,
                property: { ...action.payload?.data, attachments },
                loading: action.payload?.loading != null ? action.payload.loading : state.loading,
            };
        }
        case SET_PROPERTY_DETAILS_LOADING: {
            return {
                ...state,
                loading: action.payload?.loading,
            };
        }
        default:
            return state;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const propertyDetailsReducer = persistReducer<any>(
    propertyDetailsPersistConfig,
    propertyDetails
);
