import { useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

import {
    ListViewTable,
    MainButton,
    tListViewTableColumnDef,
    userAdminTableRowRenderer,
} from 'src/components';
import {
    balconyUserColumnHelper,
    getMunicipalityIdFromAdminUser,
    userAdministrationTableColumns,
    userIsAdmin,
} from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { NotFound } from 'src/pages';
import {
    BalconyUser,
    UserReducer,
    addUserToOrganizationAsync,
    getAllOrganizationMembersAsync,
    getExternalUsersAsync,
    getMunicipalityAsync,
    removeUserFromOrganizationAsync,
    setUserAdminPagination,
} from 'src/store';
import { tSearchPagination } from 'src/types';

import { UserManagementModal } from './UserManagementModal';

import './UserManagement.scss';

export const UserManagement = () => {
    const dispatch = useAppDispatch();
    const { user }: UserReducer = useAppSelector(state => state.userReducer);
    const { municipality } = useAppSelector(state => state.municipalitiesReducer);
    const { loadingOrganizationMembers, organizationMembers, pagination, externalUsers } =
        useAppSelector(state => state.userAdministrationReducer);
    const { pageIndex, pageSize } = pagination;
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false);
    const [selectedUserToAdd, setSelectedUserToAdd] = useState<string>('');
    useEffect(() => {
        if (!user) {
            return;
        }
        const userMunicipalityId = getMunicipalityIdFromAdminUser(user.balconyUser);
        if (municipality == null || municipality.municipality_id !== userMunicipalityId) {
            if (userMunicipalityId != null) {
                dispatch(getMunicipalityAsync(userMunicipalityId));
            }
        }

        dispatch(getAllOrganizationMembersAsync(user.thirdwebAccount));
        dispatch(getExternalUsersAsync(user.thirdwebAccount));
    }, [user]);

    if (user == null || !userIsAdmin(user)) {
        return <NotFound />;
    }

    const updatePaginationState = (paginationUpdate: tSearchPagination) => {
        dispatch(setUserAdminPagination(paginationUpdate));
    };

    const fetchMoreData = (query: Record<string, never>, paginationUpdate?: tSearchPagination) => {
        if (paginationUpdate != null) {
            dispatch(setUserAdminPagination(paginationUpdate));
        }
    };

    const makeRemoveButton = (cell: CellContext<BalconyUser, unknown>): JSX.Element => {
        const onClick = () => {
            const organizationMemberId =
                cell.row.original.organization_permissions?.organization_member_id;
            if (organizationMemberId != null) {
                dispatch(
                    removeUserFromOrganizationAsync(user.thirdwebAccount, organizationMemberId)
                );
            }
        };

        return (
            <div className="user-admin-remove-button">
                <MainButton buttonType="primary" onClick={onClick}>
                    Remove
                </MainButton>
            </div>
        );
    };

    const userAdminColumns: tListViewTableColumnDef<BalconyUser>[] = [
        ...userAdministrationTableColumns,
        balconyUserColumnHelper.accessor('display_name', {
            id: 'remove',
            header: () => 'Actions',
            cell: makeRemoveButton,
        }),
    ];

    const noUsersFound = <div className="user-management-no-users-found">No users were found</div>;

    const table = (
        <ListViewTable
            loading={loadingOrganizationMembers}
            items={organizationMembers}
            pageIndex={pageIndex}
            pageSize={pageSize}
            updatePagination={updatePaginationState}
            resultsCount={organizationMembers.length}
            queryParameters={{}}
            fetchMoreData={fetchMoreData}
            columnsDefs={userAdminColumns}
            rowRenderer={userAdminTableRowRenderer}
            noDataElement={noUsersFound}
        />
    );

    return (
        <div className="user-management container">
            <div className="user-management-header-container">
                <div className="user-management-h1-container">
                    <h1>User Management</h1>
                </div>
                <div className="add-user-button">
                    <MainButton buttonType="primary" onClick={() => setIsAddUserModalOpen(true)}>
                        <span className="user-button-text">Add</span>
                        <span>+</span>
                    </MainButton>
                </div>
            </div>
            <div className="user-management__content">{table}</div>

            <UserManagementModal
                selectedUserWalletAddress={selectedUserToAdd}
                updateSelectedUserWalletAddress={setSelectedUserToAdd}
                externalUsers={externalUsers}
                cancelFunction={() => setIsAddUserModalOpen(false)}
                showModal={isAddUserModalOpen}
                onSubmit={(walletAddress: string) => {
                    setSelectedUserToAdd('');
                    dispatch(addUserToOrganizationAsync(user.thirdwebAccount, walletAddress));
                }}
            />
        </div>
    );
};
