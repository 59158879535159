import { BaseSelect } from 'src/components';
import {
    UserAdministrationModal,
    UserAdministrationModalCancelButton,
    UserAdministrationModalMainButton,
} from 'src/components/UserAdministrationModal/UserAdministrationModal';
import { BalconyUser } from 'src/store';

export const UserManagementModal = ({
    selectedUserWalletAddress,
    updateSelectedUserWalletAddress,
    externalUsers,
    cancelFunction,
    showModal,
    onSubmit,
}: {
    selectedUserWalletAddress: string;
    updateSelectedUserWalletAddress: (walletAddress: string) => void;
    externalUsers: BalconyUser[];
    cancelFunction: () => void;
    showModal: boolean;
    onSubmit: (walletAddress: string) => void;
}): JSX.Element => {
    const selectionItems = externalUsers.map(u => {
        return {
            id: u.wallet_address,
            name: `${u.email_address} (${u.wallet_address})`,
        };
    });
    const items = [{ id: '', name: 'Choose an option' }, ...selectionItems];

    const mainContent = (
        <>
            <h4>Select user</h4>
            <BaseSelect
                selectedValue={selectedUserWalletAddress}
                items={items}
                onClick={externalUser => updateSelectedUserWalletAddress(externalUser)}
            />
        </>
    );

    const cancelButton = (
        <UserAdministrationModalCancelButton onClickFunction={() => cancelFunction()} />
    );

    const disableActionButton = selectedUserWalletAddress === '';
    const submitButton = (
        <UserAdministrationModalMainButton
            disabled={disableActionButton}
            onClickFunction={() => {
                onSubmit(selectedUserWalletAddress);
            }}
            buttonText="Add User"
        />
    );

    return (
        <UserAdministrationModal
            showModal={showModal}
            cancelButton={cancelButton}
            submitButton={submitButton}
            title="Add New User"
            description="Select a user from the dropdown below to add them to your organization."
            onClose={cancelFunction}
            mainContent={mainContent}
        />
    );
};
