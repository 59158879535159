import { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDisconnect } from 'thirdweb/react';

import { Routes as R } from 'src/constants';
import { useAppSelector, useOnClickOutside } from 'src/hooks';

import { Logout } from 'src/icons';

import defaultUser from 'src/assets/images/defaultUser.png';

import './ProfileMenu.scss';
import { userCanViewOrganization, userIsAdmin } from 'src/helpers';

export const ProfileMenu = () => {
    const { user } = useAppSelector(state => state.userReducer);
    const thirdwebAccount = user ? user.thirdwebAccount : undefined;
    const balconyUser = user ? user.balconyUser : undefined;
    const thirdwebWallet = user ? user.thirdwebWallet : undefined;
    const thirdwebEmail = user ? user.thirdwebEmail : undefined;

    if (thirdwebAccount == null || balconyUser == null) {
        return <></>;
    }

    const { disconnect } = useDisconnect();

    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const selectClickHandler = () => setOpenSelect(prevState => !prevState);
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();

    useOnClickOutside(nodeRef, () => setOpenSelect(false));

    const ProfileNavLink = ({ route, pageName }: { route: string; pageName: string }) => {
        return (
            <NavLink
                onClick={() => setOpenSelect(false)}
                to={route}
                className={({ isActive }) =>
                    isActive
                        ? 'profile-menu__content_dropdown-list-item profile-menu__content_dropdown-list-item--active'
                        : 'profile-menu__content_dropdown-list-item'
                }
            >
                {pageName}
            </NavLink>
        );
    };

    const NotificationSettingNavlink = userCanViewOrganization(user) ? (
        <ProfileNavLink route={R.NOTIFICATION_SETTINGS} pageName="Notification Settings" />
    ) : (
        <></>
    );

    const UserManagementNavLink = userIsAdmin(user) ? (
        <ProfileNavLink route={R.USER_MANAGEMENT} pageName="User Management" />
    ) : (
        <></>
    );

    const ProductPermissionsNavLink = userIsAdmin(user) ? (
        <ProfileNavLink route={R.PRODUCT_PERMISSIONS} pageName="Product Permissions" />
    ) : (
        <></>
    );

    return (
        <div className="profile-menu">
            <div ref={nodeRef} className="profile-menu__content">
                <div onClick={selectClickHandler} className="profile-menu__content_user">
                    <div
                        className="profile-menu__content_user-picture"
                        style={{
                            backgroundImage: `url(${defaultUser})`,
                        }}
                    />
                    <div className="profile-menu__content_user-text">
                        <h4>{thirdwebEmail}</h4>
                    </div>
                </div>
                <div
                    className={`profile-menu__content_dropdown profile-menu__content_dropdown--${
                        openSelect ? 'open' : 'close'
                    }`}
                >
                    <ul className="profile-menu__content_dropdown-list">
                        {NotificationSettingNavlink}
                        {UserManagementNavLink}
                        {ProductPermissionsNavLink}
                        <li
                            className="solo-list-element"
                            onClick={() => {
                                thirdwebWallet != null ? disconnect(thirdwebWallet) : null;
                                navigate(R.MAIN);
                            }}
                        >
                            <Logout /> Sign Out
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
