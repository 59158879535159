import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';

import { ThirdwebProvider } from 'thirdweb/react';

import { AuthIntegrator } from './components';

import './assets/styles/styles.scss';
import './assets/fonts/AcademyEngravedStd/AcademyEngravedStd.ttf';
import '@cloudscape-design/global-styles/index.css';

export const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <ThirdwebProvider>
            <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                    <AuthIntegrator>
                        <App />
                    </AuthIntegrator>
                </PersistGate>
            </Provider>
        </ThirdwebProvider>
    </BrowserRouter>
);

reportWebVitals();
