import {
    NOTIFICATION_MODULES,
    ORGANIZATION_NOTIFICATIONS_UNASSOCIATED_WITH_MODULES,
    AccessLevels,
    OrganizationTypes,
} from 'src/constants';
import { UserAccounts } from 'src/store';

export const userIsAdmin = (user?: UserAccounts): boolean => {
    return (
        user != null &&
        user.balconyUser.organization_permissions != null &&
        user.balconyUser.organization_permissions.is_admin
    );
};

const userIsOrganizationMember = (user?: UserAccounts): boolean => {
    return user != null && user.balconyUser.organization_permissions != null;
};

export const userIsBalconyEmployee = (user?: UserAccounts): boolean => {
    return user != null && user.balconyUser.is_balcony_employee === true;
};

export const userCanViewOrganization = (user?: UserAccounts): boolean => {
    return userIsOrganizationMember(user) || userIsBalconyEmployee(user);
};

export const userHasModulePermission = (
    moduleId: string,
    requiredAccessLevel: AccessLevels,
    user?: UserAccounts
): boolean => {
    if (
        user == null ||
        user.balconyUser.organization_permissions == null ||
        user.balconyUser.organization_permissions.access_levels == null
    ) {
        return false;
    }

    const userAccessLevel = user.balconyUser.organization_permissions.access_levels[moduleId];

    if (requiredAccessLevel === AccessLevels.READONLY) {
        return userAccessLevel === AccessLevels.READONLY || userAccessLevel === AccessLevels.EDIT;
    }

    if (requiredAccessLevel === AccessLevels.EDIT) {
        return userAccessLevel === AccessLevels.EDIT;
    }

    return false;
};

export const userOrganizationIsMunicipality = (
    municipalityId: string,
    user?: UserAccounts
): boolean => {
    if (user == null || user.balconyUser.organization_permissions == null) {
        return false;
    }
    const organizationPermissions = user.balconyUser.organization_permissions;
    return (
        organizationPermissions.organization_type === OrganizationTypes.MUNICIPAL &&
        organizationPermissions.organization_locality === municipalityId
    );
};

export const userCanViewNotificationSetting = (
    notificationType: string,
    user: UserAccounts
): boolean => {
    if (userIsBalconyEmployee(user)) {
        return true;
    }
    if (notificationType in NOTIFICATION_MODULES) {
        return userHasModulePermission(
            NOTIFICATION_MODULES[notificationType],
            AccessLevels.READONLY,
            user
        );
    }

    return (
        ORGANIZATION_NOTIFICATIONS_UNASSOCIATED_WITH_MODULES.includes(notificationType) &&
        userCanViewOrganization(user)
    );
};
