import { useEffect } from 'react';

import { ListViewTable, MainButton } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { changePropertySalesPagination } from 'src/store';
import { tSearchPagination } from 'src/types';

import { ToplineTable, propertySalesTableColumns, propertySalesTableRowRenderer } from '.';
import { dispatchLoadingCall, downloadCsv } from '../helpers';

interface IProps {
    slug?: string;
    showToplineTable?: boolean;
}

export const PropertySalesContainer = (props: IProps) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.userReducer);

    const cohState = useAppSelector(state => state.propertySalesReducer);

    const updatePaginationState = (paginationUpdate: tSearchPagination) => {
        dispatch(changePropertySalesPagination(paginationUpdate));
    };

    const fetchMoreData = (query: Record<string, never>, paginationUpdate?: tSearchPagination) => {
        if (paginationUpdate != null) {
            dispatch(changePropertySalesPagination(paginationUpdate));
        }
    };

    const {
        compliance_rate,
        percent_missing_coh,
        property_sales_count,
        property_sales_missing_coh_count,
        loading,
        property_sales,
        pagination,
        searchData,
    } = cohState;
    const { pageIndex, pageSize } = pagination;
    const { tabOption, dateRange } = searchData;

    const { slug, showToplineTable } = props;

    useEffect(() => {
        if (slug == null) {
            return;
        }
        dispatchLoadingCall(dispatch, tabOption, slug, dateRange, user);
    }, [slug]);

    const downloadCsvOnClick = async () => {
        await downloadCsv(slug, searchData, user);
    };

    const noResultsFound = (
        <div className="property-sales-no-sales-in-range">
            No sales were found in the provided date range.
        </div>
    );

    return (
        <>
            {showToplineTable ? (
                <div className="topline-table-download-links">
                    <div className="topline-stats">
                        <ToplineTable
                            complianceRate={compliance_rate}
                            percentMissingCOH={percent_missing_coh}
                            propertySalesCount={property_sales_count}
                            propertySalesMissingCOHCount={property_sales_missing_coh_count}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="tabbed-property-sales-table-container">
                <ListViewTable
                    municipality_slug={slug}
                    items={property_sales}
                    loading={loading}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    updatePagination={updatePaginationState}
                    resultsCount={property_sales.length}
                    queryParameters={{}}
                    fetchMoreData={fetchMoreData}
                    columnsDefs={propertySalesTableColumns}
                    rowRenderer={propertySalesTableRowRenderer}
                    noDataElement={noResultsFound}
                />
            </div>
            <div className="csv-button-download-row">
                <MainButton buttonType={'primary'} onClick={downloadCsvOnClick}>
                    Download as CSV
                </MainButton>
            </div>
        </>
    );
};
