import { Loader } from 'src/components';
import { AssetTaxTable, LienInfo } from 'src/pages/PropertyDetails/PropertyDetails';
import { tHouseFull } from 'src/types';

import './TaxationAssessmentTab.scss';

interface IProps {
    loading: boolean;
    property?: tHouseFull;
}

export const TaxationAssessmentTab = ({ loading, property }: IProps) => {
    if (loading || property == null) {
        return (
            <div className="property-ledger-loader">
                <div className="ledger-loader">
                    <Loader />
                </div>
            </div>
        );
    }

    const taxCollectionRecords =
        property.tax_collection_records != null ? property.tax_collection_records : [];

    return (
        <div className="property-ledger-tab-container taxation-assessment-tab">
            <AssetTaxTable
                make_payment_url={property.make_payment_url}
                taxRecords={taxCollectionRecords}
            />

            <LienInfo house={property} />
        </div>
    );
};
