import { useEffect, useState, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Footer, ListViewTable, SearchHeaderLine } from 'src/components';

import { getOccupancyStatusFromSearchParams } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    changeHousesPagination,
    getAllAddressesAsync,
    getHousesAsync,
    getMunicipalityAsync,
    clearQuery,
} from 'src/store';

import { tSearchData, tSearchPagination } from 'src/types';

import { propertyRowRenderer, searchResultsTableColumns } from './SearchResultColumns';

import './SearchResult.scss';

const ResultsCount = ({ count }: { count?: number }) => {
    if (count == null) {
        return <></>;
    }
    const resultsWord = count != 1 ? 'results' : 'result';
    const resultsCount = `${count} ${resultsWord}`;
    return (
        <div className="search-result__header">
            <div className="search-result__header_part">
                <h5>{resultsCount}</h5>
            </div>
        </div>
    );
};

export const SearchResult = () => {
    const { slug } = useParams();
    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const scrollableDiv = useRef<HTMLDivElement>(null);

    const { query, houses, count, loading, pagination } = useAppSelector(
        state => state.housesReducer
    );
    const { pageIndex, pageSize } = pagination;

    // Pull the occupancy status from the querystring.
    const occupancyStatusFromSearchParams = getOccupancyStatusFromSearchParams(searchParams);

    const [sort] = useState<string>('featured');

    const getHouses = (queryData: tSearchData, paginationUpdate?: tSearchPagination) => {
        dispatch(getHousesAsync(queryData, paginationUpdate));
    };

    const updatePaginationState = (paginationUpdate: tSearchPagination) => {
        dispatch(changeHousesPagination(paginationUpdate));
    };

    useEffect(() => {
        if (slug) {
            dispatch(getMunicipalityAsync(slug));
            dispatch(getAllAddressesAsync(slug));
            getHouses(
                {
                    ...query,
                    municipality_slug: slug,
                    sort,
                    occupancy: occupancyStatusFromSearchParams,
                },
                { pageIndex: 0 }
            );
        }
    }, [slug]);

    useEffect(() => {
        return () => {
            dispatch(clearQuery());
        };
    }, []);

    return (
        <div className="app__page search-page bg-grey" ref={scrollableDiv} id="scrollableDiv">
            <div className="search-result">
                <SearchHeaderLine slug={slug} query={query} dataLoading={loading} />
                <div className="search-result__body container">
                    <ResultsCount count={count} />
                    {count === 0 && !loading ? (
                        <h4>No results found. Please broaden your query and try again.</h4>
                    ) : (
                        <ListViewTable
                            municipality_slug={slug}
                            items={houses}
                            loading={loading}
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            updatePagination={updatePaginationState}
                            resultsCount={count}
                            fetchMoreData={getHouses}
                            queryParameters={query}
                            columnsDefs={searchResultsTableColumns}
                            rowRenderer={propertyRowRenderer}
                        />
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};
