import { FC } from 'react';
import './MunicipalityHeader.scss';

interface IProps {
    logo?: string;
    text: string;
}

export const MunicipalityHeader: FC<IProps> = ({ logo, text }) => {
    return (
        <div className="municipality-header-logo-container">
            <div className="municipality-header-logo">
                {logo != null ? <img src={logo} alt="logo" /> : <></>}
                <h2>{text}</h2>
            </div>
        </div>
    );
};
