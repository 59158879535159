import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ConnectButton } from 'thirdweb/react';

import { HeaderNavBar, Loader, MunicipalityHeader, ProfileMenu } from 'src/components';
import { getSupportedWallets, getThirdWebClient } from 'src/helpers';
import { useAppSelector } from 'src/hooks';

import './Header.scss';
import { UserConnectionStatus } from 'src/store';
import { ThirdwebClient } from 'thirdweb';
import { Account, Wallet } from 'thirdweb/wallets';

const ConnectButtonLoadingSpinner = () => {
    return (
        <button className="balcony-connect-button-spinning-button">
            <Loader color="white" />
        </button>
    );
};

interface IConnectButtonProps {
    client: ThirdwebClient;
    wallets: Wallet<'inApp'>[];
    thirdwebAccount?: Account;
    balconyConnectStatus: UserConnectionStatus;
}

const ConnectButtonWithLoadingStatus = ({
    balconyConnectStatus,
    client,
    thirdwebAccount,
    wallets,
}: IConnectButtonProps) => {
    if (thirdwebAccount != null) {
        return <ProfileMenu />;
    }
    if (balconyConnectStatus === UserConnectionStatus.Started) {
        return <ConnectButtonLoadingSpinner />;
    }

    return (
        <ConnectButton
            client={client}
            theme={'light'}
            wallets={wallets}
            connectButton={{ label: 'Sign in' }}
            connectModal={{
                showThirdwebBranding: false,
                title: 'Log in to Balcony',
            }}
        />
    );
};

export const Header = () => {
    const { balconyConnectStatus, user } = useAppSelector(state => state.userReducer);
    const thirdwebAccount = user ? user.thirdwebAccount : undefined;

    const [scroll, setScroll] = useState<boolean>(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 10);
        });
    }, []);

    const { municipality } = useAppSelector(state => state.municipalitiesReducer);

    const showMunicipalityInHeader = municipality && municipality.title != null && pathname !== '/';

    return (
        <header
            className={`header ${(pathname !== '/' || scroll) && 'header--white'} ${
                pathname === '/profile' || (pathname === '/user' && 'header--big')
            } ${showMunicipalityInHeader && 'header__with-municipality'}`}
        >
            <div className="header__content container">
                {showMunicipalityInHeader ? (
                    <MunicipalityHeader
                        logo={municipality.logo_url}
                        text={`${municipality.title} Asset Registry`}
                    />
                ) : (
                    <></>
                )}
                <HeaderNavBar municipality={municipality} />
                {
                    <div className="header__content_part header__content_part-buttons">
                        <ConnectButtonWithLoadingStatus
                            balconyConnectStatus={balconyConnectStatus}
                            client={getThirdWebClient()}
                            thirdwebAccount={thirdwebAccount}
                            wallets={getSupportedWallets()}
                        />
                    </div>
                }
            </div>
        </header>
    );
};
