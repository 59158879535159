import { URLSearchParamsInit } from 'react-router-dom';
import { OCCUPANCY_STATUS_SEARCH_PARAM, OccupancyStatus } from 'src/constants';

/**
 * These functions operate on the OccupancyStatus enum.
 * Properties can either be vacant and abandoned (marked with a boolean
 * in our db) or occupied. Users should be able to filter between them,
 * but also be able to search for all properties.
 *
 * In this app, we pull this information from a query string on the
 * SearchResults component, currently `vacant_and_abandoned`.
 *
 * We send this information to the asset-server as either the string `false`
 * or the string `true`
 */

export const occupancyStatusToApiQueryParam = (occupancyStatus: OccupancyStatus): string => {
    switch (occupancyStatus) {
        case OccupancyStatus.VACANT_AND_ABANDONED:
            return 'false';
        case OccupancyStatus.OCCUPIED:
            return 'true';
        case OccupancyStatus.ALL:
            return '';
        default:
            return '';
    }
};

export const searchParamstoOccupancyStatus = (param: string | null): OccupancyStatus => {
    switch (param) {
        case 'true':
            return OccupancyStatus.VACANT_AND_ABANDONED;
        case 'false':
            return OccupancyStatus.OCCUPIED;
        case '':
            return OccupancyStatus.ALL;
        default:
            return OccupancyStatus.ALL;
    }
};

export const occupancyStatusToSearchParam = (occupancyStatus: OccupancyStatus): string => {
    switch (occupancyStatus) {
        case OccupancyStatus.VACANT_AND_ABANDONED:
            return 'true';
        case OccupancyStatus.OCCUPIED:
            return 'false';
        case OccupancyStatus.ALL:
            return '';
        default:
            return '';
    }
};

/**
 * These functions get and set the occupancy status from the url
 *
 * The setter is provided by the React component that calls the setter,
 * put this encapsulates all URL handling logic as well as the constant
 * used for the occupancy status.
 */

export const getOccupancyStatusFromSearchParams = (
    searchParams: URLSearchParams
): OccupancyStatus => {
    return searchParamstoOccupancyStatus(searchParams.get(OCCUPANCY_STATUS_SEARCH_PARAM));
};

export const setOccupancyStatusInSearchParams = (
    occupancyStatus: OccupancyStatus,
    setSearchParamsFunction: (urlSearchParams: URLSearchParamsInit) => void
): void => {
    const searchParams =
        occupancyStatus === OccupancyStatus.ALL
            ? new URLSearchParams()
            : {
                  [OCCUPANCY_STATUS_SEARCH_PARAM]: occupancyStatusToSearchParam(occupancyStatus),
              };
    setSearchParamsFunction(searchParams);
};
