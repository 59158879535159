import ReactGA from 'react-ga4';

export const initializeGoogleAnalytics = () => {
    const gaId = process.env.REACT_APP_GA_ID;
    if (gaId == null || gaId === '') {
        return;
    }

    try {
        ReactGA.initialize(gaId);
    } catch (error) {
        console.error('Failed to initialize Google Analytics', error);
    }
};

export const trackEvent = (category: string, action: string, label: string) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};
