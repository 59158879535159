export const DOCUMENT_TYPE_NAMES: { [documentTypeId: string]: string } = {
    DEED: 'Deed',
    MORTGAGE: 'Mortgage',
    'CANCELLATION OF MORTGAGE': 'Cancellation of Mortgage',
    'DISCHARGE OF MORTGAGE': 'Discharge of Mortgate',
    'ASSIGNMENT OF MORTGAGE': 'Assignment of Mortgage',
    'NOTICE OF SETTLEMENT': 'Notice of Settlement',
    'UCC 1': 'UCC 1',
};

export const UPLOADED_DOCUMENT_TYPE_NAMES: { [documentTypeId: string]: string } = {
    PROPERTY_RESOLUTION: 'Property Resolution',
};
