import moment from 'moment';
import { FC } from 'react';
import { DateTimeFormats, dollarFormat } from 'src/helpers';
import { tSaleInfo } from 'src/types';

export type tSaleInfoRowProps = tSaleInfo & { moneyFormatter: Intl.NumberFormat };

export const SaleInfoRow: FC<tSaleInfoRowProps> = ({
    id,
    date,
    book,
    page,
    price,
    grantee,
    moneyFormatter,
}) => {
    return (
        <tr className="small">
            <td key={`cell-${id}-date`}>
                <h3 className="light">
                    {(date && moment(date).utc().format(DateTimeFormats.MMDDYY)) || '-'}
                </h3>
            </td>
            <td key={`cell-${id}-book`}>
                <h3 className="text">{book || '-'}</h3>
            </td>
            <td key={`cell-${id}-page`}>
                <h3 className="text">{page || '-'}</h3>
            </td>
            <td key={`cell-${id}-price`}>
                <h3 className="text">
                    {price != null ? dollarFormat(price, moneyFormatter) : '-'}
                </h3>
            </td>
            <td key={`cell-${id}-grantee`} width="300px">
                <h3 className="light">{grantee || '-'}</h3>
            </td>
        </tr>
    );
};
