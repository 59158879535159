import { Dispatch } from 'redux';

import { assetApiClient } from 'src/api';
import { tAssetServerMunicipality, tMunicipality } from 'src/types';

import { GET_MUNICIPALITIES, GET_MUNICIPALITY } from './action-types';
import { tGetMunicipalitiesAction, tGetMunicipalityAction } from './types';

export const changeMunicipalitiesData = (data: tMunicipality[]): tGetMunicipalitiesAction => {
    return {
        type: GET_MUNICIPALITIES,
        payload: {
            data,
        },
    };
};

export const changeMunicipalityData = (data: tMunicipality): tGetMunicipalityAction => {
    return {
        type: GET_MUNICIPALITY,
        payload: {
            data,
        },
    };
};

const enrichMunicipality = (municipality: tAssetServerMunicipality): tMunicipality => {
    const enrichedMunicipality = {
        ...municipality,
        slug: `${municipality.name} ${municipality.state}`.replace(/ /g, '-').toLowerCase(),
        title: `${municipality.name}, ${municipality.state}`,
    };

    return enrichedMunicipality;
};

export const getMunicipalityAsync = (municipalityId: string) => async (dispatch: Dispatch) => {
    try {
        const { data }: { data: tAssetServerMunicipality } = await assetApiClient.get(
            `/municipality-detail/${municipalityId}`
        );

        const enrichedMunicipality = enrichMunicipality(data);

        dispatch(changeMunicipalityData(enrichedMunicipality));
    } catch (e) {
        console.log(e);
    }
};
