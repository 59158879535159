import { FC } from 'react';
import moment from 'moment';
import { Tag } from 'src/components';
import { GREEN, GREEN_LIGHT, YELLOW, YELLOW_LIGHT } from 'src/constants';
import { DateTimeFormats } from 'src/helpers';

export type tAssetTaxesBlockRow = {
    id: string;
    total_year?: string;
    due_date?: string;
    year: string;
    type?: string;
    billed: string;
    adjustedBilled: string;
    balance: string;
    interest: string;
    status?: 'open' | 'paid';
    tx?: string;
    total: string;
    momentDueDate: moment.Moment;
};
interface IRow extends tAssetTaxesBlockRow {
    className?: string;
}
export interface IAssetTaxesYearProps {
    id: string;
    total: string;
    year: string;
    billed: string;
    balance: string;
    interest: string;
    adjustedBilled: string;
    data: tAssetTaxesBlockRow[];
    yearMoment: moment.Moment;
}
const Row: FC<IRow> = ({
    id,
    year,
    total_year = '',
    due_date = '',
    type = '',
    billed,
    adjustedBilled,
    balance,
    interest,
    status = '',
    className,
    total,
}) => {
    return (
        <tr className={className}>
            <td key={`cell-${id}-year`} width="115px">
                {year && <span className="outline">{year}</span>}
            </td>
            <td key={`cell-${id}-due_date`}>
                <h3 className="light">
                    {due_date ? moment(due_date).utc().format(DateTimeFormats.MMDDYY) : total_year}
                </h3>
            </td>
            <td key={`cell-${id}-type`}>
                <h3 className="text">{type}</h3>
            </td>
            <td key={`cell-${id}-billed`}>
                <h3 className="title">{billed}</h3>
            </td>
            <td key={`cell-${id}-adjusted-billed`}>
                <h3 className="title">{adjustedBilled}</h3>
            </td>
            <td key={`cell-${id}-balance`}>
                <h3 className="title">{balance}</h3>
            </td>
            <td key={`cell-${id}-interest`}>
                <h3 className="title">{interest}</h3>
            </td>
            <td key={`cell-${id}-total`}>
                <h3 className="title">{total}</h3>
            </td>
            <td key={`cell-${id}-status`} width="115px">
                {status && (
                    <Tag
                        size="s"
                        text={status}
                        bgColor={status === 'open' ? YELLOW_LIGHT : GREEN_LIGHT}
                        textColor={status === 'open' ? YELLOW : GREEN}
                    ></Tag>
                )}
            </td>
        </tr>
    );
};
export const AssetTaxesBlock: FC<IAssetTaxesYearProps> = ({
    id,
    year,
    billed,
    balance,
    interest,
    adjustedBilled,
    data,
    yearMoment,
    total,
}) => {
    return (
        <>
            {data.map((el, id) => {
                return <Row key={`${year}-taxes-${id}`} {...el} className="small" />;
            })}
            <Row
                id={id}
                total_year="Total Year"
                year={year}
                billed={billed}
                balance={balance}
                interest={interest}
                adjustedBilled={adjustedBilled}
                className="violet"
                momentDueDate={yearMoment}
                total={total}
            />
        </>
    );
};
