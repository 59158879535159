import { Account } from 'thirdweb/wallets';

import {
    assetApiClient,
    makeSignedDeleteRequest,
    makeSignedPostRequest,
    makeSignedPutRequest,
} from 'src/api';
import {
    DOCUMENT_SIGNATURE_VERSION,
    generateDocumentSignature,
    generateHashDigest,
    getBase64StringFromFile,
    getUnixTimestamp,
} from 'src/helpers';

export const uploadFile = async (
    thirdwebAccount: Account,
    propertyId: string,
    file: File,
    notes: string,
    updatedFilename: string
) => {
    const hashDigest = await generateHashDigest(file);

    const signatureVersion = 1;

    const unixTimestamp = getUnixTimestamp();

    const signature = await generateDocumentSignature(
        thirdwebAccount,
        hashDigest,
        DOCUMENT_SIGNATURE_VERSION,
        unixTimestamp
    );

    const base64String = await getBase64StringFromFile(file);
    if (base64String == null) {
        console.error('Error uploading file to server');
        return;
    }
    const filenameForUpload = updatedFilename != '' ? updatedFilename : file.name;
    const encodedString = base64String.replace(/^data:(.*,)?/, '');
    const body = {
        base64File: encodedString,
        fileName: filenameForUpload,
        hashDigest,
        notes,
        signature,
        signatureTimestamp: `${unixTimestamp}`,
        signatureVersion: `${signatureVersion}`,
    };

    await makeSignedPostRequest(
        assetApiClient,
        thirdwebAccount,
        `/property/${propertyId}/documents?documentType=PROPERTY_RESOLUTION`,
        body
    );
};

export const editUserUploadedPropertyDocument = async (
    thirdwebAccount: Account,
    propertyId: string,
    documentId: number,
    updatedFilename: string
) => {
    await makeSignedPutRequest(
        assetApiClient,
        thirdwebAccount,
        `/property/${propertyId}/documents/${documentId}`,
        { file_name: updatedFilename }
    );
};

export const deleteUserUploadedPropertyDocument = async (
    thirdwebAccount: Account,
    propertyId: string,
    documentId: number
) => {
    await makeSignedDeleteRequest(
        assetApiClient,
        thirdwebAccount,
        `/property/${propertyId}/documents/${documentId}`
    );
};
