import React, { useState, FocusEvent, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActiveWallet, useDisconnect } from 'thirdweb/react';
import { getUserEmail } from 'thirdweb/wallets/in-app';

import { MainButton, Modal } from 'src/components';
import { Routes as R } from 'src/constants';
import { getThirdWebClient, validateField } from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { abandonRegistration, setRegistrationErrors, registerThirdwebUser } from 'src/store';

import { AuthInput } from '../components/';
import { AuthWrap } from '../components';

import './RegisterModal.scss';

const initState = {
    fullName: '',
    displayName: '',
};

export const RegisterModal = () => {
    const { disconnect } = useDisconnect();
    const { registrationErrors, registeringUser } = useAppSelector(state => state.userReducer);
    const navigate = useNavigate();
    const [values, setValues] = useState(initState);
    const dispatch = useAppDispatch();
    const client = getThirdWebClient();
    const activeWallet = useActiveWallet();

    useEffect(() => {
        return () => {
            dispatch(
                setRegistrationErrors({ fullName: null, displayName: null, registration: null })
            );
        };
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isFullNameValid = !runValidation('fullName', values.fullName);
        const isDisplayNameValid = !runValidation('displayName', values.displayName);
        const email = await getUserEmail({ client });

        let address = '';
        if (registeringUser != null) {
            const account = registeringUser.account;
            if (account != null && account.address != null) {
                address = account.address;
            }
        }

        if (email == null || email == '' || address == '' || registeringUser == null) {
            dispatch(
                setRegistrationErrors({
                    registration: 'An email or address could not be derived from Thirdweb',
                })
            );
            return;
        }

        if (isFullNameValid && isDisplayNameValid) {
            dispatch(
                registerThirdwebUser(
                    registeringUser.account,
                    values.fullName,
                    values.displayName,
                    address,
                    email
                )
            );
        }
    };

    const handleChange = (type: string) => (value: string) => {
        setValues(prevState => ({ ...prevState, [type]: value }));
    };

    const runValidation = (type: string, value: string) => {
        const fieldError = validateField(type, value);
        dispatch(setRegistrationErrors({ [type]: fieldError }));
        return fieldError;
    };

    const handleFocus = (type: string) => (e: FocusEvent<HTMLInputElement>) => {
        runValidation(type, e.target.value);
    };

    const handleClose = () => {
        if (registeringUser != null) {
            disconnect(registeringUser.wallet);
        } else {
            if (activeWallet != null) {
                disconnect(activeWallet);
            }
        }
        dispatch(abandonRegistration());
        navigate(R.MAIN);
    };

    const header = (
        <>
            <p>Complete Registration</p>
            <div>
                <span>Please provide your full name, and a shorter name for communications.</span>
            </div>
        </>
    );

    const errorDisplay = registrationErrors.registration ? (
        <div className="auth-header register-modal-err_message">
            <div>
                <span>{registrationErrors.registration}</span>
            </div>
        </div>
    ) : (
        <></>
    );

    return (
        <Modal width={620} show={true} onClose={handleClose}>
            <AuthWrap header={header}>
                <form onSubmit={handleSubmit}>
                    <AuthInput
                        addClass={'auth-input'}
                        value={values.fullName}
                        messageError={
                            registrationErrors.fullName ? registrationErrors.fullName : undefined
                        }
                        placeholder={'Full Name'}
                        onChangeValue={handleChange('fullName')}
                        onBlur={handleFocus('fullName')}
                        label={'Full Name'}
                    />
                    <AuthInput
                        addClass={'auth-input'}
                        value={values.displayName}
                        messageError={
                            registrationErrors.displayName
                                ? registrationErrors.displayName
                                : undefined
                        }
                        placeholder={'Display Name'}
                        onChangeValue={handleChange('displayName')}
                        onBlur={handleFocus('displayName')}
                        label={'Display Name'}
                    />
                    {errorDisplay}
                    <div className="auth-btns-row">
                        <MainButton buttonType={'primary'} buttonSize={'l'} type={'submit'}>
                            Finish Registration
                        </MainButton>
                    </div>
                </form>
            </AuthWrap>
        </Modal>
    );
};

export const RegisterModalDisplay = () => {
    const modal = useAppSelector(state => state.userReducer.showRegistrationModal);

    return modal ? <RegisterModal /> : <></>;
};
