import { Dispatch } from 'redux';
import { Account } from 'thirdweb/wallets';

import { assetApiClient, makeSignedGetRequest, makeSignedPutRequest } from 'src/api';
import {
    UPDATE_NOTIFICATION_SETTINGS,
    UPDATE_NOTIFICATION_SETTINGS_LOADING,
    UPDATE_SINGLE_NOTIFICATION_SETTING,
} from './action-types';
import { NotificationSettingsByMunicipality } from './types';

export const updateNotificationSettingsLoading = (loading: boolean) => {
    return {
        type: UPDATE_NOTIFICATION_SETTINGS_LOADING,
        payload: {
            loading,
        },
    };
};

export const updateNotificationSettings = (
    notificationSettings: NotificationSettingsByMunicipality
) => {
    return {
        type: UPDATE_NOTIFICATION_SETTINGS,
        payload: { notificationSettings },
    };
};

export const setSingleNotificationSetting = (
    notificationTypeName: string,
    isSubscribed: boolean
) => {
    return {
        type: UPDATE_SINGLE_NOTIFICATION_SETTING,
        payload: { notificationTypeName, isSubscribed },
    };
};

export const getNotificationSettings =
    (municipalityId: string, account: Account) => async (dispatch: Dispatch) => {
        dispatch(updateNotificationSettingsLoading(true));
        try {
            const { data } = await makeSignedGetRequest(
                assetApiClient,
                account,
                `/user-notification-settings/${municipalityId}`
            );
            dispatch(updateNotificationSettings(data));
        } catch (e) {
            dispatch(updateNotificationSettingsLoading(false));
            console.error('An error occurred while loading notification settings', e);
        }
    };

export const updateSingleNotificationSetting =
    (
        notificationTypeName: string,
        municipalityId: string,
        isSubscribed: boolean,
        account: Account
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(setSingleNotificationSetting(notificationTypeName, isSubscribed));
        try {
            const url = `/user-notification-settings/${municipalityId}/${notificationTypeName}`;
            const body = { is_subscribed: isSubscribed };
            makeSignedPutRequest(assetApiClient, account, url, body);
        } catch (e) {
            console.error('An error occurred while updating notification settings', e);
            dispatch(setSingleNotificationSetting(notificationTypeName, !isSubscribed));
        }
    };
