import './Tag.scss';
import { FC, MouseEvent } from 'react';

interface IProps {
    text: string;
    bgColor: string;
    textColor: string;
    size?: 's' | 'm' | 'max';
    onClick?: (e: MouseEvent) => void;
    borderColor?: string;
}
export const Tag: FC<IProps> = ({ text, bgColor, textColor, size = 'm', onClick, borderColor }) => {
    const borderStyles: { [key: string]: string } = {};
    if (borderColor) {
        borderStyles['border'] = `1px solid ${borderColor}`;
    }
    return onClick ? (
        <button
            className={`tag tag--${size}`}
            style={{ backgroundColor: bgColor, color: textColor }}
            onClick={e => onClick(e)}
        >
            {text}
        </button>
    ) : (
        <span
            className={`tag tag--${size}`}
            style={{ backgroundColor: bgColor, color: textColor, ...borderStyles }}
        >
            {text}
        </span>
    );
};
