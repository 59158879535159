import { CellContext } from '@tanstack/react-table';
import { AccessLevels, ORGANIZATION_MODULE_NAMES } from 'src/constants';
import { BalconyUser, MemberAccessLevels, OrganizationPermissions } from 'src/store';

export const getActiveUserModuleCount = (cell: CellContext<BalconyUser, unknown>): number => {
    let featuresEnabled = 0;
    if (
        cell.row.original.organization_permissions != null &&
        cell.row.original.organization_permissions.access_levels != null
    ) {
        Object.values(cell.row.original.organization_permissions.access_levels).forEach(
            accessLevel => {
                if (accessLevel === AccessLevels.EDIT || accessLevel === AccessLevels.READONLY) {
                    featuresEnabled += 1;
                }
            }
        );
    }
    return featuresEnabled;
};

export const populateAllModuleAccessLevels = (
    enabledModuleIds: string[],
    organizationMemberAccessLevels?: MemberAccessLevels
): MemberAccessLevels => {
    const allAccessLevels: MemberAccessLevels = {};
    enabledModuleIds.forEach(moduleId => {
        if (
            organizationMemberAccessLevels == null ||
            organizationMemberAccessLevels[moduleId] == null
        ) {
            allAccessLevels[moduleId] = '';
        } else {
            allAccessLevels[moduleId] = organizationMemberAccessLevels[moduleId];
        }
    });

    return allAccessLevels;
};

export const getPopulatedAccessLevelsFromUser = (
    userOrganizationPermissions: OrganizationPermissions,
    enabledModuleIds: string[]
): MemberAccessLevels => {
    const accessLevelsFromUser =
        userOrganizationPermissions.access_levels != null
            ? userOrganizationPermissions.access_levels
            : {};

    return populateAllModuleAccessLevels(enabledModuleIds, accessLevelsFromUser);
};

type MemberAccessLevelsUpdate = {
    newMemberAccessLevels: MemberAccessLevels;
    moduleIdsToRevoke: string[];
};

export const getAccessLevelsToUpdate = (
    oldAccessLevels: MemberAccessLevels,
    newAccessLevels?: MemberAccessLevels
): MemberAccessLevelsUpdate | undefined => {
    const newMemberAccessLevels: MemberAccessLevels = {};
    const moduleIdsToRevoke: string[] = [];
    let needToUpdate = false;

    if (newAccessLevels == null) {
        return undefined;
    }

    Object.keys(newAccessLevels).forEach(moduleId => {
        if (newAccessLevels[moduleId] !== oldAccessLevels[moduleId]) {
            needToUpdate = true;
            if (newAccessLevels[moduleId] === '') {
                moduleIdsToRevoke.push(moduleId);
            } else {
                newMemberAccessLevels[moduleId] = newAccessLevels[moduleId];
            }
        }
    });

    if (needToUpdate) {
        return { newMemberAccessLevels, moduleIdsToRevoke };
    }
    return undefined;
};

export const makeModulePermissionRow = (
    moduleId: string,
    accessLevel: string,
    updateModulePermission: (moduleId: string, accessLevel: string) => void
) => {
    return (
        <div className="product-permissions-module-row" key={moduleId}>
            <div className="product-permissions-module-row-module-name">
                {moduleId in ORGANIZATION_MODULE_NAMES
                    ? ORGANIZATION_MODULE_NAMES[moduleId]
                    : moduleId}
            </div>
            <div className="radio">
                <label>
                    <input
                        type="radio"
                        value="readonly"
                        onChange={() => updateModulePermission(moduleId, AccessLevels.READONLY)}
                        checked={accessLevel === AccessLevels.READONLY}
                    />
                    {` Readonly`}
                </label>
            </div>
            <div className="radio">
                <label>
                    <input
                        type="radio"
                        value="edit"
                        onChange={() => updateModulePermission(moduleId, AccessLevels.EDIT)}
                        checked={accessLevel === AccessLevels.EDIT}
                    />
                    {` Edit`}
                </label>
            </div>
            <div className="radio">
                <label>
                    <input
                        type="radio"
                        value="disable"
                        onChange={() => updateModulePermission(moduleId, '')}
                        checked={
                            accessLevel !== AccessLevels.EDIT &&
                            accessLevel !== AccessLevels.READONLY
                        }
                    />
                    {` Disable`}
                </label>
            </div>
        </div>
    );
};
