import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Footer } from 'src/components';
import { Routes as R } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { clearPropertyDetails, getMunicipalityAsync, getPropertyDetailsAsync } from 'src/store';
import { PropertyHeader } from './PropertyHeader';
import { DocumentsTab, SalesTab, TaxationAssessmentTab, PropertySummary } from './components';
import './PropertyLedger.scss';

export const PropertyLedger = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { slug, property_id } = useParams();

    const { property, loading } = useAppSelector(state => state.propertyDetailsReducer);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (slug) {
            dispatch(getMunicipalityAsync(slug));
        }
    }, [slug]);
    useEffect(() => {
        if (!property_id || property_id === null || property_id === 'null') {
            navigate(R.MAIN);
        } else {
            dispatch(clearPropertyDetails());
            dispatch(getPropertyDetailsAsync(property_id as string));
        }
    }, [property_id]);

    return (
        <>
            <div className="app__page property-ledger">
                <div className="property-ledger-container">
                    {!loading && property && <PropertyHeader property={property} slug={slug} />}
                    <Tabs
                        focusTabOnClick={false}
                        selectedIndex={tabIndex}
                        onSelect={index => setTabIndex(index)}
                    >
                        <div className="property-ledger__tabs-header-wrapper">
                            <div className="property-ledger__tabs-header container">
                                <TabList className="property-ledger__tab-list">
                                    <Tab>Property Summary</Tab>
                                    <Tab>Sales and Transactions</Tab>
                                    <Tab>Taxation and Assessment</Tab>
                                    <Tab>Property Documents</Tab>
                                </TabList>
                            </div>
                        </div>
                        <div className="tabbed-property-sales__tabs-body-wrapper">
                            <div className="tabbed-property-sales__tabs-body container">
                                <TabPanel>
                                    <PropertySummary loading={loading} property={property} />
                                </TabPanel>
                                <TabPanel>
                                    <SalesTab loading={loading} property={property} />
                                </TabPanel>
                                <TabPanel>
                                    <TaxationAssessmentTab loading={loading} property={property} />
                                </TabPanel>
                                <TabPanel>
                                    <DocumentsTab loading={loading} property={property} />
                                </TabPanel>
                            </div>
                        </div>
                    </Tabs>
                </div>
                <Footer />
            </div>
        </>
    );
};
