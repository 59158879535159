import { useState } from 'react';
import {
    UserAdministrationModal,
    UserAdministrationModalCancelButton,
    UserAdministrationModalMainButton,
} from 'src/components';
import { BalconyUser, MemberAccessLevels } from 'src/store';

import {
    getAccessLevelsToUpdate,
    getPopulatedAccessLevelsFromUser,
    populateAllModuleAccessLevels,
    makeModulePermissionRow,
} from './helpers';

export const ProductPermissionsModal = ({
    enabledModuleIds,
    showModal,
    cancelFunction,
    userToUpdate,
    onClickFunction,
}: {
    enabledModuleIds: string[];
    showModal: boolean;
    cancelFunction: () => void;
    userToUpdate: BalconyUser | undefined;
    onClickFunction: (
        organizationMemberId: number,
        accessLevels: MemberAccessLevels,
        modulesToRevoke: string[]
    ) => void;
}) => {
    const [accessLevelsToApply, setAccessLevelsToApply] = useState<MemberAccessLevels | undefined>(
        undefined
    );

    const productPermissionsModalDescription = (
        <>
            Set the module permissions for users using the radio buttons below.
            <br />
            {`"Save changes"`} to apply the updates.
        </>
    );

    const modalDescriptionWithoutEnabledModules = (
        <>No enabled modules were found for your organization.</>
    );

    const modalDescription =
        enabledModuleIds.length > 0
            ? productPermissionsModalDescription
            : modalDescriptionWithoutEnabledModules;

    const cancelButton = (
        <UserAdministrationModalCancelButton
            onClickFunction={() => {
                setAccessLevelsToApply(undefined);
                cancelFunction();
            }}
        />
    );

    if (userToUpdate == null || userToUpdate.organization_permissions == null) {
        const submitButton = (
            <UserAdministrationModalMainButton
                disabled={true}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClickFunction={() => {}}
                buttonText="Save Changes"
            />
        );
        return (
            <UserAdministrationModal
                showModal={showModal}
                title="Manage Permissions"
                cancelButton={cancelButton}
                description={modalDescription}
                onClose={() => {
                    setAccessLevelsToApply(undefined);
                    cancelFunction();
                }}
                mainContent={<></>}
                submitButton={submitButton}
            />
        );
    }

    const userOrganizationPermissions = userToUpdate.organization_permissions;
    const updateMemberAccessLevel = (moduleId: string, permissionLevel: string): void => {
        const updatedAccessLevels =
            accessLevelsToApply != null
                ? { ...accessLevelsToApply }
                : {
                      ...getPopulatedAccessLevelsFromUser(
                          userOrganizationPermissions,
                          enabledModuleIds
                      ),
                  };

        updatedAccessLevels[moduleId] = permissionLevel;

        setAccessLevelsToApply(updatedAccessLevels);
    };

    const accessLevelsFromUser =
        userOrganizationPermissions.access_levels != null
            ? userOrganizationPermissions.access_levels
            : {};

    const accessLevelsToDisplay =
        accessLevelsToApply != null
            ? accessLevelsToApply
            : populateAllModuleAccessLevels(enabledModuleIds, accessLevelsFromUser);

    const mainContent = (
        <>
            {Object.keys(accessLevelsToDisplay).map(moduleId =>
                makeModulePermissionRow(
                    moduleId,
                    accessLevelsToDisplay[moduleId],
                    updateMemberAccessLevel
                )
            )}
        </>
    );

    const memberAccessLevelsUpdate = getAccessLevelsToUpdate(
        populateAllModuleAccessLevels(enabledModuleIds, userOrganizationPermissions.access_levels),
        accessLevelsToApply
    );
    const newMemberAccessLevels =
        memberAccessLevelsUpdate != null ? memberAccessLevelsUpdate.newMemberAccessLevels : {};
    const moduleIdsToRevoke =
        memberAccessLevelsUpdate != null ? memberAccessLevelsUpdate.moduleIdsToRevoke : [];

    const submitButton = (
        <UserAdministrationModalMainButton
            disabled={memberAccessLevelsUpdate == null}
            onClickFunction={() => {
                setAccessLevelsToApply(undefined);
                cancelFunction();
                onClickFunction(
                    userOrganizationPermissions.organization_member_id,
                    newMemberAccessLevels,
                    moduleIdsToRevoke
                );
            }}
            buttonText="Save Changes"
        />
    );

    return (
        <UserAdministrationModal
            showModal={showModal}
            title="Manage Permissions"
            cancelButton={cancelButton}
            description={modalDescription}
            onClose={() => {
                setAccessLevelsToApply(undefined);
                cancelFunction();
            }}
            mainContent={mainContent}
            submitButton={submitButton}
        />
    );
};
