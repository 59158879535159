import { base58btc } from 'multiformats/bases/base58';
import { CID } from 'multiformats/cid';
import { sha256 as hasher } from 'multiformats/hashes/sha2';
import { Account } from 'thirdweb/wallets';

const DAG_PB_CODE = 0x70;

export const DOCUMENT_SIGNATURE_VERSION = 1;

export const generateHashDigest = async (file: File): Promise<string> => {
    const buff = await file.arrayBuffer();
    const uint8Array = new Uint8Array(buff);
    const hash = await hasher.digest(uint8Array);
    const cid = CID.create(0, DAG_PB_CODE, hash);
    return cid.toString(base58btc);
};

export const getUnixTimestamp = (): number => {
    return Math.floor(Date.now() / 1000);
};

export const generateDocumentSignature = async (
    thirdwebAccount: Account,
    hashDigest: string,
    signatureVersion: number,
    signatureTimestamp: number
): Promise<string> => {
    const objectToSign = {
        hashDigest: hashDigest,
        signatureTimestamp: `${signatureTimestamp}`,
        signatureVersion: `${signatureVersion}`,
    };
    return thirdwebAccount.signMessage({
        message: JSON.stringify(objectToSign),
    });
};

export const isFileUploadEnabled = (): boolean =>
    (process.env.REACT_APP_ALLOW_DOCUMENT_UPLOAD as string) === 'true';

export const getBase64StringFromFile = (file: File): Promise<string | undefined> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            resolve(typeof reader.result === 'string' ? reader.result : undefined);
        reader.onerror = reject;
    });
