import { Dispatch } from 'redux';
import { Account, Wallet, WalletId } from 'thirdweb/wallets';

import { assetApiClient, makeSignedPostRequest } from 'src/api/apiClient';

import {
    ABANDON_REGISTRATION,
    BEGIN_REGISTRATION,
    FINISH_REGISTRATION,
    LOGIN_USER,
    LOGOUT_USER,
    SET_REGISTRATION_ERRORS,
    UPDATE_USER_AUTOCONNECT_STATUS,
    UPDATE_USER_BALCONY_CONNECT_STATUS,
} from './action-types';
import { BalconyUser, UserConnectionStatus } from './types';
import { getThirdWebClient } from 'src/helpers';
import { getUserEmail } from 'thirdweb/wallets/in-app';

export const loginUser = (
    thirdwebAccount: Account,
    balconyUser: BalconyUser,
    thirdwebWallet: Wallet<WalletId>,
    thirdwebEmail: string
) => {
    return {
        type: LOGIN_USER,
        payload: {
            thirdwebAccount,
            balconyUser,
            thirdwebWallet,
            thirdwebEmail,
        },
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};

export const beginRegistration = (
    thirdwebWallet: Wallet<WalletId>,
    thirdwebAccount: Account,
    thirdwebEmail: string
) => {
    return {
        type: BEGIN_REGISTRATION,
        payload: {
            registeringWallet: thirdwebWallet,
            registeringUser: thirdwebAccount,
            registeringEmail: thirdwebEmail,
        },
    };
};

export const abandonRegistration = () => {
    return {
        type: ABANDON_REGISTRATION,
    };
};

export const setRegistrationErrors = (errors: { [fieldName: string]: string | null }) => {
    return {
        type: SET_REGISTRATION_ERRORS,
        payload: {
            errors,
        },
    };
};

const finishRegistration = (user: BalconyUser) => {
    return {
        type: FINISH_REGISTRATION,
        payload: {
            balconyUser: user,
        },
    };
};

export const updateAutoconnectStatus = (autoconnectStatus: boolean) => {
    return {
        type: UPDATE_USER_AUTOCONNECT_STATUS,
        payload: {
            autoconnectStatus,
        },
    };
};

export const updateBalconyConnectStatus = (balconyConnectStatus: UserConnectionStatus) => {
    return {
        type: UPDATE_USER_BALCONY_CONNECT_STATUS,
        payload: {
            balconyConnectStatus,
        },
    };
};

export const logThirdwebUserIntoBalcony =
    (thirdwebAccount: Account, thirdwebWallet: Wallet<WalletId>) => async (dispatch: Dispatch) => {
        dispatch(updateBalconyConnectStatus(UserConnectionStatus.Started));
        const client = getThirdWebClient();
        const thirdwebEmailFromClient = await getUserEmail({ client });
        const thirdwebEmail = thirdwebEmailFromClient ? thirdwebEmailFromClient : '';
        const body = { walletAddress: thirdwebAccount.address };
        try {
            const response = await makeSignedPostRequest(
                assetApiClient,
                thirdwebAccount,
                `/user/login`,
                body
            );
            const balconyUser: BalconyUser = response.data;

            dispatch(loginUser(thirdwebAccount, balconyUser, thirdwebWallet, thirdwebEmail));
        } catch (e) {
            dispatch(beginRegistration(thirdwebWallet, thirdwebAccount, thirdwebEmail));
        }
    };

export const logoutThirdwebUser = () => async (dispatch: Dispatch) => {
    dispatch(logoutUser());
};

export const registerThirdwebUser =
    (
        thirdwebAccount: Account,
        fullName: string,
        displayName: string,
        walletAddress: string,
        emailAddress: string
    ) =>
    async (dispatch: Dispatch) => {
        const body = { walletAddress, fullName, displayName, emailAddress };

        try {
            const response = await makeSignedPostRequest(
                assetApiClient,
                thirdwebAccount,
                `/user/register`,
                body
            );
            dispatch(finishRegistration(response.data));
        } catch (e) {
            dispatch(
                setRegistrationErrors({ registration: 'An error occurred while registering.' })
            );
            console.error(e);
        }
    };
