import { useState } from 'react';
import { default as CloudscapeModal } from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import FileUpload, { FileUploadProps } from '@cloudscape-design/components/file-upload';
import Container from '@cloudscape-design/components/container';

import { getPropertyDetailsAsync } from 'src/store';
import { UserAccounts } from 'src/store';

import './FileUploadModal.scss';
import { useAppDispatch } from 'src/hooks';
import { uploadFile } from '../../api';

// Max file size is 4 MB
const MAX_FILE_SIZE = 4194304;

export const FileUploadModal = ({
    visible,
    onDismiss,
    propertyId,
    user,
    setAlert,
}: {
    visible: boolean;
    onDismiss: () => void;
    propertyId: string;
    user?: UserAccounts;
    setAlert: (alert?: string) => void;
}) => {
    const dispatch = useAppDispatch();
    const [files, setFiles] = useState<File[]>([]);
    const [updatedFilename, setUpdatedFilename] = useState('');

    const i18nStrings: FileUploadProps.I18nStrings = {
        uploadButtonText: () => 'Choose file',
        dropzoneText: () => 'Drop file to upload',
        limitShowMore: 'Show more files',
        limitShowFewer: 'Show fewer files',
        removeFileAriaLabel: () => 'remove',
    };

    const uploadDisabled = files.length == 0 || files[0].size > MAX_FILE_SIZE;
    const fileSizeExceeded = files.length > 0 && files[0].size > MAX_FILE_SIZE;
    const errorText = fileSizeExceeded ? 'File size is above 4 MB' : undefined;

    return (
        <CloudscapeModal
            onDismiss={() => onDismiss()}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={uploadDisabled}
                            onClick={async () => {
                                if (user != null && files.length > 0) {
                                    try {
                                        await uploadFile(
                                            user.thirdwebAccount,
                                            propertyId,
                                            files[0],
                                            '',
                                            updatedFilename
                                        );
                                        dispatch(getPropertyDetailsAsync(propertyId));
                                        onDismiss();
                                    } catch (e) {
                                        console.error('Failed to upload document', e);
                                        setAlert('Failed to upload document');
                                        onDismiss();
                                    }
                                }
                            }}
                        >
                            Upload
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Document Upload"
        >
            <Container>
                <FormField label="Property File Upload" constraintText="Select a file to upload">
                    <FileUpload
                        value={files}
                        onChange={changedFiles => {
                            setFiles(changedFiles.detail.value);
                        }}
                        errorText={errorText}
                        constraintText="File size should not exceed 4 MB"
                        i18nStrings={i18nStrings}
                        showFileSize
                        showFileLastModified
                    />
                </FormField>
            </Container>
            <div className="input-file-name">
                <FormField
                    constraintText="Requirements and constraints for the field."
                    label="File Name - optional"
                >
                    <Input
                        value={updatedFilename}
                        onChange={e => setUpdatedFilename(e.detail.value)}
                    />
                </FormField>
            </div>
        </CloudscapeModal>
    );
};
