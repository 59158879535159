import { UserAdministrationReducer } from './types';
export const initialState: UserAdministrationReducer = {
    organizationMembers: [],
    loadingOrganizationMembers: false,
    externalUsers: [],
    loadingExternalUsers: false,
    pagination: {
        pageIndex: 0,
        pageSize: 10,
    },
    loadingEnabledModules: false,
    enabledModules: [],
};
