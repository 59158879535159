import { COH_COMPLIANCE_MODULE_ID } from './modules';

export const NOTIFICATION_TYPE_NAMES = {
    MONTHLY_COH_EMAIL: 'monthly_coh_email',
};

export const NOTIFICATION_MODULES = {
    [NOTIFICATION_TYPE_NAMES.MONTHLY_COH_EMAIL]: COH_COMPLIANCE_MODULE_ID,
};

// Some notifications may not be associated with a module.
// This is a list of those notifications.
export const ORGANIZATION_NOTIFICATIONS_UNASSOCIATED_WITH_MODULES: string[] = [];

export const NOTIFICATION_NAMES: { [notificationName: string]: string } = {
    [NOTIFICATION_TYPE_NAMES.MONTHLY_COH_EMAIL]: 'CoH Compliant Sales',
};
