import { AccessLevels, COH_COMPLIANCE_MODULE_ID } from 'src/constants';
import {
    userHasModulePermission,
    userIsBalconyEmployee,
    userOrganizationIsMunicipality,
} from 'src/helpers';
import { UserAccounts } from 'src/store';

/**
 * Determine if a user can access the CoH Compliance tab on the Property Sales page.
 *
 * A user can view the CoH Compliance tab if:
 * 1. The user is logged in and we have a non-empty municipality id from the URL slug
 * 2. The user is a member of a Balcony organization
 * 3. That user has `readonly` or `edit` access to the `coh_compliance` module
 * 4. The user is a municipal organization member and that municipality's id matches
 *    the slug from the URL
 *
 * This will need to be updated when we begin enrolling counties in the CoH Compliance module,
 * or any othe module.
 *
 * @param user a set of objects representing a Balcony user account and a Thirdweb account
 * @param slug a municipality id taken from the URL
 * @returns boolean whether or not we should display the CoH Compliance tab for a user
 */
export const userCanAccessCoHTab = (user?: UserAccounts, slug?: string): boolean => {
    if (user == null || slug == null || slug == '') {
        return false;
    }

    if (userIsBalconyEmployee(user)) {
        return true;
    }

    return (
        userHasModulePermission(COH_COMPLIANCE_MODULE_ID, AccessLevels.READONLY, user) &&
        userOrganizationIsMunicipality(slug, user)
    );
};
