import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Account } from 'thirdweb/wallets';

const BASE_URL_PREFIX = '/api/v1';

export const assetApiClient = axios.create({
    baseURL: `${process.env.REACT_APP_ASSET_URL}${BASE_URL_PREFIX}`,
    withCredentials: false,
});

export const makeSignedGetRequest = async (
    apiClient: AxiosInstance,
    thirdwebAccount: Account,
    url: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> => {
    const signature = await thirdwebAccount.signMessage({ message: BASE_URL_PREFIX + url });
    return apiClient.get(url, {
        headers: { BALCONY_SIGNATURE: signature, BALCONY_ADDRESS: thirdwebAccount.address },
    });
};

export const makeSignedPutRequest = async <T>(
    apiClient: AxiosInstance,
    thirdwebAccount: Account,
    url: string,
    body: T
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> => {
    const signature =
        body != null
            ? await thirdwebAccount.signMessage({
                  message: BASE_URL_PREFIX + url + JSON.stringify(body),
              })
            : await thirdwebAccount.signMessage({
                  message: BASE_URL_PREFIX + url,
              });
    return apiClient.put(url, body, {
        headers: { BALCONY_SIGNATURE: signature, BALCONY_ADDRESS: thirdwebAccount.address },
    });
};

export const makeSignedPostRequest = async <T>(
    apiClient: AxiosInstance,
    thirdwebAccount: Account,
    url: string,
    body: T
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> => {
    const signature = await thirdwebAccount.signMessage({
        message: BASE_URL_PREFIX + url + JSON.stringify(body),
    });
    return apiClient.post(url, body, {
        headers: {
            BALCONY_SIGNATURE: signature,
            BALCONY_ADDRESS: thirdwebAccount.address,
        },
    });
};

export const makeSignedDeleteRequest = async (
    apiClient: AxiosInstance,
    thirdwebAccount: Account,
    url: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> => {
    const signature = await thirdwebAccount.signMessage({ message: BASE_URL_PREFIX + url });
    return apiClient.delete(url, {
        headers: { BALCONY_SIGNATURE: signature, BALCONY_ADDRESS: thirdwebAccount.address },
    });
};
