import React, { FC } from 'react';
import './AuthWrap.scss';

type tProps = {
    children: React.ReactNode;
    header: React.ReactNode;
};

export const AuthWrap: FC<tProps> = ({ children, header }) => {
    return (
        <div className="auth-content">
            <div className="auth-header">{header}</div>
            <div className="auth-header"></div>
            {children}
        </div>
    );
};
