import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initialState } from './initialState';
import { tUserAdministrationAction, UserAdministrationReducer } from './types';
import {
    USER_ADMINISTRATION_GET_ENABLED_MODULES,
    USER_ADMINISTRATION_GET_EXTERNAL_USERS,
    USER_ADMINISTRATION_GET_ORGANIZATION_MEMBERS,
    USER_ADMINISTRATION_SET_LOADING,
    USER_ADMINISTRATION_SET_PAGINATION_STATE,
} from './action-types';
import { updateReducerPaginationState } from 'src/helpers';

const userAdministrationPersistConfig = {
    key: 'userAdministrationState',
    storage: storage,
    whitelist: [],
};

const userAdministration = (
    state = initialState,
    action: tUserAdministrationAction
): UserAdministrationReducer => {
    switch (action.type) {
        case USER_ADMINISTRATION_GET_ORGANIZATION_MEMBERS: {
            return {
                ...state,
                loadingOrganizationMembers: false,
                organizationMembers: action.payload.data,
            };
        }

        case USER_ADMINISTRATION_GET_ENABLED_MODULES: {
            return {
                ...state,
                enabledModules: action.payload.enabledModuleIds,
                loadingEnabledModules: false,
            };
        }

        case USER_ADMINISTRATION_SET_PAGINATION_STATE: {
            return {
                ...state,
                pagination: updateReducerPaginationState(
                    state.pagination,
                    action.payload.pagination
                ),
            };
        }

        case USER_ADMINISTRATION_GET_EXTERNAL_USERS: {
            return {
                ...state,
                loadingExternalUsers: false,
                externalUsers: action.payload.data,
            };
        }

        case USER_ADMINISTRATION_SET_LOADING: {
            return {
                ...state,
                loadingOrganizationMembers:
                    action.payload.organizationLoading != null
                        ? action.payload.organizationLoading
                        : state.loadingOrganizationMembers,
                loadingExternalUsers:
                    action.payload.loadingExternalUsers != null
                        ? action.payload.loadingExternalUsers
                        : state.loadingExternalUsers,
                loadingEnabledModules:
                    action.payload.loadingEnabledModules != null
                        ? action.payload.loadingEnabledModules
                        : state.loadingEnabledModules,
            };
        }

        default:
            return state;
    }
};

export const userAdministrationReducer = persistReducer<
    UserAdministrationReducer,
    tUserAdministrationAction
>(userAdministrationPersistConfig, userAdministration);
