import { Dispatch } from 'redux';
import { Account } from 'thirdweb/wallets';

import {
    assetApiClient,
    makeSignedDeleteRequest,
    makeSignedGetRequest,
    makeSignedPutRequest,
} from 'src/api';
import { BalconyUser, MemberAccessLevels } from 'src/store';
import { tSearchPagination } from 'src/types';

import {
    USER_ADMINISTRATION_GET_ENABLED_MODULES,
    USER_ADMINISTRATION_GET_EXTERNAL_USERS,
    USER_ADMINISTRATION_GET_ORGANIZATION_MEMBERS,
    USER_ADMINISTRATION_SET_LOADING,
    USER_ADMINISTRATION_SET_PAGINATION_STATE,
} from './action-types';
import {
    tGetEnabledModules,
    tGetExternalUsers,
    tGetOrganizationMembers,
    tSetUserAdminLoading,
    tSetAdminPaginationState,
    tUserAdministrationLoadingUpdate,
} from './types';

const setExternalUsers = (users: BalconyUser[] | null): tGetExternalUsers => {
    const userArray = users != null ? users : [];
    return {
        type: USER_ADMINISTRATION_GET_EXTERNAL_USERS,
        payload: {
            data: userArray,
        },
    };
};

const setOrganizationMembers = (users: BalconyUser[]): tGetOrganizationMembers => {
    return {
        type: USER_ADMINISTRATION_GET_ORGANIZATION_MEMBERS,
        payload: {
            data: users,
        },
    };
};

export const setUserAdminPagination = (
    paginationUpdate: tSearchPagination
): tSetAdminPaginationState => {
    return {
        type: USER_ADMINISTRATION_SET_PAGINATION_STATE,
        payload: {
            pagination: paginationUpdate,
        },
    };
};

const setUserAdminLoading = (
    loadingUpdate: tUserAdministrationLoadingUpdate
): tSetUserAdminLoading => {
    return {
        type: USER_ADMINISTRATION_SET_LOADING,
        payload: loadingUpdate,
    };
};

const setEnabledModuleIds = (enabledModuleIds: string[] | null): tGetEnabledModules => {
    const enabledModuleIdsArray = enabledModuleIds != null ? enabledModuleIds : [];
    return {
        type: USER_ADMINISTRATION_GET_ENABLED_MODULES,
        payload: {
            enabledModuleIds: enabledModuleIdsArray,
        },
    };
};

const getAllOrganizationMembers = async (thirdwebAccount: Account): Promise<BalconyUser[]> => {
    const response = await makeSignedGetRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/organization/users`
    );
    const { data } = response;
    return data;
};

const getExternalUsers = async (thirdwebAccount: Account): Promise<BalconyUser[] | null> => {
    const response = await makeSignedGetRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/users`
    );
    const { data } = response;
    return data;
};

const removeUserFromOrganization = async (
    thirdwebAccount: Account,
    organizationMemberId: number
): Promise<unknown> => {
    const response = await makeSignedDeleteRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/organization/${organizationMemberId}`
    );
    const { data } = response;
    return data;
};

const addUserToOrganization = async (
    thirdwebAccount: Account,
    walletAddress: string
): Promise<unknown> => {
    const response = await makeSignedPutRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/organization/${walletAddress}`,
        undefined
    );
    const { data } = response;
    return data;
};

const revokeModulePermission = async (
    thirdwebAccount: Account,
    organizationMemberId: number,
    moduleId: string
): Promise<unknown> => {
    const response = await makeSignedDeleteRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/organization/permissions/${organizationMemberId}/${moduleId}`
    );
    const { data } = response;
    return data;
};

const grantModulePermission = async (
    thirdwebAccount: Account,
    organizationMemberId: number,
    moduleId: string,
    accessLevel: string
): Promise<unknown> => {
    const response = await makeSignedPutRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/organization/permissions/${organizationMemberId}`,
        { accessLevel, moduleId }
    );
    const { data } = response;
    return data;
};

const getAllEnabledModuleIds = async (thirdwebAccount: Account): Promise<string[] | null> => {
    const response = await makeSignedGetRequest(
        assetApiClient,
        thirdwebAccount,
        `/user-administration/organization/modules`
    );
    const { data } = response;
    return data;
};

export const getAllOrganizationMembersAsync =
    (thirdwebAccount: Account) => async (dispatch: Dispatch) => {
        dispatch(setUserAdminLoading({ organizationLoading: true }));
        try {
            const data = await getAllOrganizationMembers(thirdwebAccount);
            dispatch(setOrganizationMembers(data));
        } catch (e) {
            dispatch(setUserAdminLoading({ organizationLoading: false }));
            console.error(e);
        }
    };

export const getExternalUsersAsync = (thirdwebAccount: Account) => async (dispatch: Dispatch) => {
    dispatch(setUserAdminLoading({ loadingExternalUsers: true }));
    try {
        const data = await getExternalUsers(thirdwebAccount);
        dispatch(setExternalUsers(data));
    } catch (e) {
        dispatch(setUserAdminLoading({ loadingExternalUsers: false }));
        console.error(e);
    }
};

export const removeUserFromOrganizationAsync =
    (thirdwebAccount: Account, organizationMemberId: number) => async (dispatch: Dispatch) => {
        dispatch(setUserAdminLoading({ organizationLoading: true, loadingExternalUsers: true }));
        try {
            await removeUserFromOrganization(thirdwebAccount, organizationMemberId);
            dispatch(getAllOrganizationMembersAsync(thirdwebAccount));
            dispatch(getExternalUsersAsync(thirdwebAccount));
        } catch (e) {
            dispatch(
                setUserAdminLoading({ organizationLoading: false, loadingExternalUsers: false })
            );
            console.error(e);
        }
    };

export const addUserToOrganizationAsync =
    (thirdwebAccount: Account, walletAddress: string) => async (dispatch: Dispatch) => {
        dispatch(setUserAdminLoading({ organizationLoading: true, loadingExternalUsers: true }));
        try {
            await addUserToOrganization(thirdwebAccount, walletAddress);
            dispatch(getAllOrganizationMembersAsync(thirdwebAccount));
            dispatch(getExternalUsersAsync(thirdwebAccount));
        } catch (e) {
            dispatch(
                setUserAdminLoading({ organizationLoading: false, loadingExternalUsers: false })
            );
            console.error(e);
        }
    };

export const getEnabledModuleIdsAsync =
    (thirdwebAccount: Account) => async (dispatch: Dispatch) => {
        dispatch(setUserAdminLoading({ loadingEnabledModules: true }));
        try {
            const enabledModuleIds = await getAllEnabledModuleIds(thirdwebAccount);
            dispatch(setEnabledModuleIds(enabledModuleIds));
        } catch (e) {
            dispatch(setUserAdminLoading({ loadingEnabledModules: false }));
            console.error(e);
        }
    };

export const updateModulePermissionsAsync =
    (
        thirdwebAccount: Account,
        organizationMemberId: number,
        permissionsToUpdate: MemberAccessLevels,
        moduleIdsToRevoke: string[]
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(setUserAdminLoading({ organizationLoading: true }));
        try {
            for (const moduleId of Object.keys(permissionsToUpdate)) {
                await grantModulePermission(
                    thirdwebAccount,
                    organizationMemberId,
                    moduleId,
                    permissionsToUpdate[moduleId]
                );
            }

            for (const moduleId of moduleIdsToRevoke) {
                await revokeModulePermission(thirdwebAccount, organizationMemberId, moduleId);
            }

            dispatch(getAllOrganizationMembersAsync(thirdwebAccount));
        } catch (e) {
            dispatch(getAllOrganizationMembersAsync(thirdwebAccount));
            console.error(e);
        }
    };
