export const ABANDON_REGISTRATION = 'ABANDON_REGISTRATION';
export const BEGIN_REGISTRATION = 'BEGIN_REGISTRATION';
export const SET_REGISTRATION_ERRORS = 'SET_REGISTRATION_ERRORS';
export const FINISH_REGISTRATION = 'FINISH_REGISTRATION';

export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const UPDATE_USER_AUTOCONNECT_STATUS = 'UPDATE_USER_AUTOCONNECT_STATUS';
export const UPDATE_USER_BALCONY_CONNECT_STATUS = 'UPDATE_USER_BALCONY_CONNECT_STATUS';
