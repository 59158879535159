import './LinkButton.scss';

import { FC, ReactNode, MouseEvent } from 'react';
import { tButton } from 'src/interfaces';

interface IProps extends tButton {
    text: string;
    className?: string;
    children?: ReactNode;
    onClick: (e: MouseEvent) => void;
    fontSize?: 'small' | 'medium' | 'large';
}

export const LinkButton: FC<IProps> = ({ text, className, children, onClick, fontSize }) => {
    let textElem = <h5>{text}</h5>;
    if (fontSize != null && fontSize === 'medium') {
        textElem = <h4>{text}</h4>;
    }
    if (fontSize != null && fontSize === 'large') {
        textElem = <h3>{text}</h3>;
    }
    return (
        <button className={`link-button ${className}`} onClick={e => onClick(e)}>
            {textElem}
            {children}
        </button>
    );
};
