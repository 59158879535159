import './SearchAdditionLine.scss';
import { FC } from 'react';
import { MainInput } from 'src/components';
import { OccupancyStatus } from 'src/constants';
import { tSearchData, tInfo } from 'src/types';
import { MainInputDropdown } from '../Inputs/MainInputDropdown';

interface IProps extends tSearchData {
    onChangeInfo: ({ key, val }: tInfo) => void;
}
export const SearchAdditionLine: FC<IProps> = ({
    block,
    lot,
    qualifier,
    occupancy,
    onChangeInfo,
}) => {
    // const inputs = [
    //     { placeholder: 'Start type name', label: 'Owner Name', key: 'owner_name' },
    //     { placeholder: 'Enter block number', label: 'Block', key: 'block' },
    //     { placeholder: 'Enter lot number', label: 'Lot', key: 'lot' },
    //     { placeholder: 'Enter a qualifier', label: 'Qualifier', key: 'qualifier' },
    //     { placeholder: 'Enter an ID', label: 'Tax Account ID', key: 'tax' },
    // ];
    return (
        <div className="search-line">
            <MainInput
                placeholder="Enter block number"
                label="Block"
                value={block}
                onChangeValue={(val: string) => onChangeInfo({ key: 'block', val })}
            />
            <MainInput
                placeholder="Enter lot number"
                label="Lot"
                value={lot}
                onChangeValue={(val: string) => onChangeInfo({ key: 'lot', val })}
            />
            <MainInput
                placeholder="Enter a qualifier"
                label="Qualifier"
                value={qualifier}
                onChangeValue={(val: string) => onChangeInfo({ key: 'qualifier', val })}
            />
            <MainInputDropdown
                items={[
                    OccupancyStatus.ALL,
                    OccupancyStatus.VACANT_AND_ABANDONED,
                    OccupancyStatus.OCCUPIED,
                ]}
                value={occupancy}
                onSelect={(val: string) => onChangeInfo({ key: 'occupancy', val })}
                placeholder="Select an occupancy"
                label="Occupancy"
                className="occupancy-select"
                disableTyping={true}
            />
        </div>
    );
};
