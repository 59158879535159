import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { NOTIFICATION_NAMES } from 'src/constants';
import { NotificationSetting } from 'src/store';

import './NotificationSettingsToggles.scss';

interface IProps {
    notificationSettings: NotificationSetting[];
    onToggleChange: (e: React.ChangeEvent<HTMLInputElement>, notificationTypeName: string) => void;
}

interface ISettingToggleProp {
    notificationSetting: NotificationSetting;
    onToggleChange: (e: React.ChangeEvent<HTMLInputElement>, notificationTypeName: string) => void;
}

const NotificationSettingToggle = ({ notificationSetting, onToggleChange }: ISettingToggleProp) => {
    const label = NOTIFICATION_NAMES[notificationSetting.notification_type_name]
        ? NOTIFICATION_NAMES[notificationSetting.notification_type_name]
        : notificationSetting.notification_type_name;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onToggleChange(e, notificationSetting.notification_type_name);
    };

    return (
        <div className="notification-toggle-container">
            <div className="notification-label">{label}</div>
            <div className="notification-toggle">
                <Toggle
                    checked={notificationSetting.is_subscribed}
                    icons={false}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export const NotificationSettingsToggles = ({ notificationSettings, onToggleChange }: IProps) => {
    return (
        <div className="notification-settings-details-section">
            {notificationSettings.map(notificationSetting => (
                <NotificationSettingToggle
                    key={`notification-setting-${notificationSetting.notification_type_name}`}
                    notificationSetting={notificationSetting}
                    onToggleChange={onToggleChange}
                />
            ))}
        </div>
    );
};
