export const getMunicipalitySlugOrThrow = (
    slug?: string,
    slugObject?: { slug: string }
): string => {
    if (slug) {
        return slug;
    }
    if (slugObject) {
        return slugObject.slug;
    }
    throw new Error('No slug or fallback slug provided');
};
