import { InfoCard } from 'src/components';

import { fractionToPercent } from 'src/helpers';

const TOPLINE_TABLE_PRECISION = 0;

type tToplineTableProps = {
    complianceRate?: number;
    propertySalesCount?: number;
    propertySalesMissingCOHCount?: number;
    percentMissingCOH?: number;
};

export const ToplineTable = ({
    complianceRate,
    propertySalesCount,
    propertySalesMissingCOHCount,
    percentMissingCOH,
}: tToplineTableProps) => {
    const propertiesSalesCountDisplay = propertySalesCount != null ? `${propertySalesCount}` : '-';
    const propertiesMissingCOHCountDisplay =
        propertySalesMissingCOHCount != null ? `${propertySalesMissingCOHCount}` : '-';
    const percentMissingCOHDisplay =
        percentMissingCOH != null
            ? fractionToPercent(percentMissingCOH, TOPLINE_TABLE_PRECISION)
            : '-';
    const complianceRateDisplay =
        complianceRate != null ? fractionToPercent(complianceRate, TOPLINE_TABLE_PRECISION) : '-';
    return (
        <div className="tabbed-property-sales__topline-table">
            <div>
                <div className="tabbed-property-sales__grid tabbed-property-sales__grid--nine container">
                    <div className="tabbed-property-sales__grid_item highlight-failure-rate">
                        <InfoCard
                            title="Property Sales Missing COH"
                            text={propertiesMissingCOHCountDisplay}
                        />
                    </div>
                    <div className="tabbed-property-sales__grid_item">
                        <InfoCard title="Property Sales" text={propertiesSalesCountDisplay} />
                    </div>
                    <div className="tabbed-property-sales__grid_item highlight-failure-rate">
                        <InfoCard title="Percent Missing COH" text={percentMissingCOHDisplay} />
                    </div>
                    <div className="tabbed-property-sales__grid_item house__grid_item--big">
                        <InfoCard title="Compliance Rate" text={complianceRateDisplay} />
                    </div>
                </div>
            </div>
        </div>
    );
};
