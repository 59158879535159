export const dotsAfterText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return (
            text
                .trim()
                .slice(0, maxLength - 3)
                .trim() + '...'
        );
    }
    return text;
};

export const dotsInText = (text: string, count?: number) => {
    if (text.length > 10) {
        const array = text.split('');
        const start = array.slice(0, count ? count : 5).join('');
        const end = array.slice(count ? -count : -5).join('');
        return `${start}...${end}`;
    } else return text;
};

export const truncateHash = (hash: string): string => {
    if (hash.slice(0, 2) !== '0x') {
        console.warn(`Hash provided to truncateHash does not start with 0x. Provided: "${hash}"`);
    }

    if (hash.length < 10) {
        console.warn(`Hash provided to truncateHash is too short. Provided: "${hash}"`);
    }

    return `${hash.slice(0, 6)}...${hash.slice(-4)}`;
};
