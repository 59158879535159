import { generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { BaseSelect } from 'src/components';
import { MenuNames, Routes } from 'src/constants';
import { tMunicipality } from 'src/types';

import './HeaderNavBar.scss';

interface IProps {
    municipality?: tMunicipality;
}

export const HeaderNavBar = ({ municipality }: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (municipality == null || municipality.municipality_id == null) {
        return <></>;
    }

    // Define the objects used to create the desktop and mobile nav elements
    const assetRegistryNavItem = {
        id: generatePath(Routes.CITY_RESULT, {
            slug: municipality.municipality_id,
        }),
        name: MenuNames.CITY_RESULT,
        route: Routes.CITY_RESULT,
    };
    const propertySalesNavItem = {
        id: generatePath(Routes.PROPERTY_SALES, {
            slug: municipality.municipality_id,
        }),
        name: MenuNames.PROPERTY_SALES,
        route: Routes.PROPERTY_SALES,
    };

    const navItems = [assetRegistryNavItem, propertySalesNavItem];

    // Create the mobile nav Select
    const selectedNavItem = navItems.reduce((activePath, navLink) => {
        return matchPath(navLink.route, location.pathname) ? navLink.id : activePath;
    }, '');
    const mobileNavSelect =
        navItems.length > 1 ? (
            <BaseSelect
                size="header-nav-bar-select"
                selectedValue={selectedNavItem}
                items={navItems}
                onClick={e => navigate(e)}
            />
        ) : (
            <></>
        );

    // Create the desktop nav elements
    const makeDesktopNavElement = (linkInfo: { id: string; name: MenuNames; route: Routes }) => {
        const activeLinkClass = matchPath(linkInfo.route, location.pathname) ? 'active' : '';
        return (
            <span
                className={activeLinkClass}
                onClick={e => {
                    e.stopPropagation();
                    navigate(linkInfo.id);
                }}
            >
                {linkInfo.name}
            </span>
        );
    };

    const desktopLinks =
        navItems.length > 1 ? navItems.map(link => makeDesktopNavElement(link)) : [];

    return (
        <>
            <div className="header-nav-bar-desktop">
                {desktopLinks.length > 1 ? (
                    desktopLinks.map((linkElement, i) => {
                        return <div key={i}>{linkElement}</div>;
                    })
                ) : (
                    <></>
                )}
            </div>
            <div className="mobile-nav-select">{mobileNavSelect}</div>
        </>
    );
};
