import { OccupancyStatus } from 'src/constants';

export const initialState = {
    query: {
        address: '',
        owner_name: '',
        block: '',
        lot: '',
        qualifier: '',
        tax: '',
        occupancy: OccupancyStatus.ALL,
    },
    houses: [],
    has_more: false,
    count: undefined,
    loading: false,
    pagination: {
        pageIndex: 0,
        pageSize: 10,
    },
    addressesForAutocomplete: [],
};
