import './PropertyDetails.scss';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import moment from 'moment';

import {
    BackButton,
    BaseCard,
    InfoCard,
    InfoLine,
    LinkButton,
    Loader,
    Map,
    Modal,
    Table,
} from 'src/components';
import {
    ASSET_TAXES_COLUMNS,
    LIEN_INFO_COLUMNS,
    MENU,
    SALE_INFO_COLUMNS,
    TAXES_COLUMNS,
} from './data';
import { taxesList, tAssetTaxesList } from 'src/mockData/data';
import { IconBlock, IconCalendar, IconLot, IconTax, IconOwner, IconPin } from 'src/icons';
import {
    AssetTaxesBlock,
    CalendarModal,
    Docs,
    DocsTab,
    SaleInfoRow,
    SaleModal,
    TaxesBlock,
} from './components';
import { useEffect, useState } from 'react';
import { tAssetLien, tAssetLienMoment, tDocs, tHouseFull, tSaleInfo } from 'src/types';
import {
    convertPropertyDocument,
    DateTimeFormats,
    dollarFormat,
    dotsAfterText,
    formatBooleanInCaps,
    formatBooleanYesNo,
    makeDollarsCentsFormatter,
    makeSnowtraceTestnetAddress,
    makeSnowtraceTestnetTransactionAddress,
    nullIfZero,
    propertyOwnerAddressFormatter,
    truncateHash,
} from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { clearPropertyDetails, getPropertyDetailsAsync, getMunicipalityAsync } from 'src/store';
import { tAssetTaxCollectionRecord, tAssetTaxCollectionRecordFormatted } from 'src/types';

import { Routes as R, ORANGE_NJ_GOV, EDMUNDS_ASSOC } from 'src/constants';
import { TableSourceRow } from './components';
import { HistoryModal } from './components/SaleModal';
import { IAssetTaxesYearProps, tAssetTaxesBlockRow } from './components/TableRow/AssetTaxesBlock';
import { LienInfoRow } from './components/TableRow';
import { Footer } from 'src/components';

const addFormattedUsdValues = (
    records: tAssetTaxCollectionRecord[],
    formatter: Intl.NumberFormat
): tAssetTaxCollectionRecordFormatted[] => {
    return records.map((record): tAssetTaxCollectionRecordFormatted => {
        const detailedRecord = {
            ...record,
            formattedOriginalBilledUsd: dollarFormat(record.original_billed_usd, formatter),
            formattedAdjustedBilledUsd: dollarFormat(record.adjusted_billed_usd, formatter),
            formattedBalanceUsd: dollarFormat(record.balance_usd, formatter),
            formattedInterestUsd: dollarFormat(record.interest_usd, formatter),
            formattedTotal: dollarFormat(record.balance_usd + record.interest_usd, formatter),
            formattedDueDate: moment(record.due_date).utc().format(DateTimeFormats.MMDDYY),
            momentDueDate: moment(record.due_date).utc(),
        };

        return detailedRecord;
    });
};

const makeTaxCollectionTableData = (records: tAssetTaxCollectionRecord[]): tAssetTaxesList[] => {
    if (!records) {
        records = [];
    }

    const formatter = makeDollarsCentsFormatter();

    const formattedRecords = addFormattedUsdValues(records, formatter);

    const taxesByYear = formattedRecords.reduce((acc, record) => {
        if (!acc[record.year]) {
            acc[record.year] = [];
        }
        acc[record.year].push(record);
        return acc;
    }, {} as { [year: number]: tAssetTaxCollectionRecordFormatted[] });

    const taxesByYearReturn = [];

    for (const [year, taxes] of Object.entries(taxesByYear)) {
        const taxesByYearData: tAssetTaxesBlockRow[] = taxes.map((tax, index) => {
            return {
                id: `${year}-${index}`,
                due_date: tax.formattedDueDate,
                year: '',
                type: 'Tax',
                billed: tax.formattedOriginalBilledUsd,
                adjustedBilled: tax.formattedAdjustedBilledUsd,
                interest: tax.formattedInterestUsd,
                balance: tax.formattedBalanceUsd,
                total: tax.formattedTotal,
                status: tax.paid_in_full ? 'paid' : 'open',
                momentDueDate: moment(tax.due_date).utc(),
            };
        });

        taxesByYearData.sort((a, b) => {
            if (a.momentDueDate < b.momentDueDate) {
                return -1;
            }
            if (a.momentDueDate > b.momentDueDate) {
                return 1;
            }
            return 0;
        });

        const totalBalancesAndBilled = taxes.reduce(
            (acc, tax) => {
                acc.original_billed_usd += tax.original_billed_usd;
                acc.adjusted_billed_usd += tax.adjusted_billed_usd;
                acc.balance_usd += tax.balance_usd;
                acc.interest_usd += tax.interest_usd;
                acc.total += tax.balance_usd;
                acc.total += tax.interest_usd;
                return acc;
            },
            {
                adjusted_billed_usd: 0,
                balance_usd: 0,
                interest_usd: 0,
                original_billed_usd: 0,
                total: 0,
            }
        );

        const taxesByYear: IAssetTaxesYearProps = {
            id: year,
            year: year,
            billed: dollarFormat(totalBalancesAndBilled.original_billed_usd, formatter),
            balance: dollarFormat(totalBalancesAndBilled.balance_usd, formatter),
            interest: dollarFormat(totalBalancesAndBilled.interest_usd, formatter),
            adjustedBilled: dollarFormat(totalBalancesAndBilled.adjusted_billed_usd, formatter),
            total: dollarFormat(totalBalancesAndBilled.total, formatter),
            data: taxesByYearData,
            yearMoment: moment(year, DateTimeFormats.YYYY),
        };

        taxesByYearReturn.push(taxesByYear);
    }

    taxesByYearReturn.sort((a, b) => {
        if (a.yearMoment < b.yearMoment) {
            return 1;
        }
        if (a.yearMoment > b.yearMoment) {
            return -1;
        }
        return 0;
    });

    return taxesByYearReturn;
};

export const AssetTaxTable = ({
    make_payment_url,
    taxRecords,
}: {
    make_payment_url: string | undefined;
    taxRecords: tAssetTaxCollectionRecord[];
}) => {
    const formattedRecords = makeTaxCollectionTableData(taxRecords);

    return (
        <div className="container">
            <div className="bg-white with-source">
                <div className="house__all-info-block__header">
                    <h2>Tax Collection History</h2>
                    <div className="house__all-info-block__header_part">
                        {make_payment_url && (
                            <LinkButton
                                text="Make a Payment"
                                onClick={() => {
                                    window.open(make_payment_url, '_blank');
                                }}
                                fontSize="medium"
                            />
                        )}
                    </div>
                </div>
                <Table columns={ASSET_TAXES_COLUMNS} className="with-padding">
                    {formattedRecords.map(row => {
                        return <AssetTaxesBlock key={`taxes-row-${row.id}`} {...row} />;
                    })}
                </Table>
                <TableSourceRow source={EDMUNDS_ASSOC} />
            </div>
        </div>
    );
};

// DEPRECATED
// This component was used exclusively in demo.balcony.technology
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AdminTaxTable = ({ make_payment_url }: { make_payment_url: string }) => {
    return (
        <div className="container">
            <div className="bg-white">
                <div className="house__all-info-block__header">
                    <h2>Tax Collection History</h2>
                    <div className="house__all-info-block__header_part">
                        {make_payment_url && (
                            <LinkButton
                                text="Make a Payment"
                                onClick={() => {
                                    window.open(make_payment_url, '_blank');
                                }}
                                fontSize="medium"
                            />
                        )}
                    </div>
                </div>
                <Table columns={TAXES_COLUMNS} className="with-padding">
                    {taxesList?.map(row => {
                        return <TaxesBlock key={`taxes-row-${row.id}`} {...row} />;
                    })}
                </Table>
            </div>
        </div>
    );
};

const ADDITIONAL_DETAILS_MAX_LENGTH = 38;
export const TwoColumnAdditionalDetails = ({ house }: { house: tHouseFull }) => {
    const {
        bldg_desc,
        building_class,
        contract_address,
        smart_contract_uri,
        estate_type,
        land_description,
        prior_block,
        prior_lot,
        prior_qual,
        record_year,
        total_taxes_usd,
        vacant,
        zone,
        zone_note,
        zone_pdf_url,
    } = house;

    // Derive properties for the `Lien` row
    const hasLien = propertyHasLien(house);
    const hasLienText = formatBooleanYesNo(hasLien);
    const urlForLien = urlForLienTable(hasLien);

    const snowtraceTestnetAddress = makeSnowtraceTestnetAddress(contract_address);

    const tokenIdText = house.token_id ? house.token_id : '-';
    const tokenIdLink = smart_contract_uri;
    const tokenIdLine = <InfoLine title="Token ID" text={tokenIdText} url={tokenIdLink} />;

    const contractAddressLine = (
        <InfoLine
            title="Contract Address"
            text={contract_address != null ? truncateHash(contract_address) : '-'}
            url={snowtraceTestnetAddress}
        />
    );

    return (
        <div className="house__additional-details container">
            <h2>Details</h2>
            <div className="house__grid house__grid--two">
                <div className="house__grid_item">
                    <InfoLine title="Record Year" text={record_year ? record_year : null} />
                    <InfoLine title="Zone" text={zone} url={zone_pdf_url} />
                    <InfoLine
                        title="Zone Description"
                        text={
                            zone_note
                                ? dotsAfterText(zone_note, ADDITIONAL_DETAILS_MAX_LENGTH)
                                : null
                        }
                    />
                    <InfoLine title="Vacant and Abandoned" text={formatBooleanInCaps(vacant)} />
                    <InfoLine title="Prior Block" text={prior_block} />
                    <InfoLine title="Prior Lot" text={prior_lot} />
                    <InfoLine title="Prior Qual" text={prior_qual} />
                    <InfoLine
                        title="Building Class"
                        text={building_class ? building_class : null}
                    />
                </div>
                <div className="house__grid_item">
                    <InfoLine
                        title="Has Lien"
                        text={hasLienText}
                        url={urlForLien}
                        openInNewTab={false}
                    />
                    <InfoLine
                        title="Total Taxes"
                        text={
                            total_taxes_usd
                                ? `$${dollarFormat(total_taxes_usd, makeDollarsCentsFormatter())}`
                                : null
                        }
                    />
                    <InfoLine title="Owner Name" text={house.owner != null ? house.owner : '-'} />
                    <InfoLine
                        title="Owner Address"
                        text={dotsAfterText(
                            propertyOwnerAddressFormatter(house),
                            ADDITIONAL_DETAILS_MAX_LENGTH
                        )}
                    />
                    <InfoLine title="Land Desc" text={land_description} />
                    <InfoLine title="Property Desc" text={estate_type} />
                    <InfoLine title="Building Description" text={bldg_desc} />
                    {tokenIdLine}
                    {contractAddressLine}
                </div>
            </div>
        </div>
    );
};

export const SaleInfo = ({ sale_information }: { sale_information: tSaleInfo[] }) => {
    if (sale_information && sale_information.length > 0) {
        return (
            <div className="container">
                <div className="bg-white with-source">
                    <div className="house__all-info-block__header">
                        <h2>Sale Information</h2>
                    </div>
                    <Table columns={SALE_INFO_COLUMNS}>
                        {sale_information.map((el: tSaleInfo, id: number) => {
                            return (
                                <SaleInfoRow
                                    key={`sale-info-${id}`}
                                    {...el}
                                    moneyFormatter={makeDollarsCentsFormatter()}
                                />
                            );
                        })}
                    </Table>
                    <TableSourceRow source={ORANGE_NJ_GOV} />
                </div>
            </div>
        );
    }
    return <></>;
};

const makeAssetDocs = (house: tHouseFull) => {
    if (!house.property_documents || house.property_documents.length === 0) {
        return <></>;
    }
    return (
        <div className="bg-transparent container" key="asset-documents">
            <h2>Public Ownership Docs</h2>
            <Docs
                id="asset-documents-id"
                list={house.property_documents.map(convertPropertyDocument)}
                dockey={'asset-documents-key'}
                disabled={{
                    value: false,
                    text: '',
                }}
                showNameTooltip={true}
            />
        </div>
    );
};

const getLastTaxPaymentAndDateFromTaxCollectionRecords = (house: tHouseFull): [string, string] => {
    const defaultReturn: [string, string] = ['N/A', 'N/A'];
    if (!house.tax_collection_records || house.tax_collection_records.length === 0) {
        return defaultReturn;
    }

    // For every tax collection record,
    // see if its adjusted billed amount is negative.
    // If it is, that means an amout was paid. Otherwise, the amount
    // is 0, and no payment was made, or it was positive, and is a credit.
    // In either case it should not be displayed here.
    const recordsWithMoments = house.tax_collection_records
        .filter(record => {
            const balanceBilled = record.adjusted_billed_usd + record.original_billed_usd;
            return record.balance_usd < balanceBilled;
        })
        .map(record => {
            const dueDateMoment = moment(record.due_date).utc();
            return { ...record, dueDateMoment };
        });

    recordsWithMoments.sort((a, b) => {
        if (a.dueDateMoment < b.dueDateMoment) {
            return 1;
        }
        if (a.dueDateMoment > b.dueDateMoment) {
            return -1;
        }
        return 0;
    });

    if (recordsWithMoments.length < 1) {
        return defaultReturn;
    }

    const latestPayment = recordsWithMoments[0];

    const paidAmount =
        latestPayment.adjusted_billed_usd +
        latestPayment.original_billed_usd -
        latestPayment.balance_usd;

    const lastAmount = dollarFormat(paidAmount, makeDollarsCentsFormatter());
    const lastDate = latestPayment.dueDateMoment.format(DateTimeFormats.MMDDYY);

    return [`$${lastAmount}`, lastDate];
};

const LIEN_INFORMATION_ID = 'lien-information';

// A property has a lien if there are one or more lien records associated with it.
const propertyHasLien = (house: tHouseFull): boolean => {
    const { liens } = house;
    return liens != null && liens.length > 0;
};

// If a property has a lien, then we want to be able to construct a link
// to the appropriate anchor tag on the page
const urlForLienTable = (hasLien: boolean): string | undefined => {
    return hasLien ? `#${LIEN_INFORMATION_ID}` : undefined;
};

export const LienInfo = ({ house }: { house: tHouseFull }) => {
    const { liens } = house;
    if (!liens || liens.length === 0) {
        return <></>;
    }

    const liensWithDates = liens.map((lien: tAssetLien): tAssetLienMoment => {
        return { ...lien, saleDateMoment: moment(lien.sale_date_day, DateTimeFormats.YYYYMMDD) };
    });

    liensWithDates.sort((a, b) => {
        if (a.saleDateMoment < b.saleDateMoment) {
            return -1;
        }
        if (a.saleDateMoment > b.saleDateMoment) {
            return 1;
        }
        return 0;
    });

    return (
        <div className="container" id={LIEN_INFORMATION_ID}>
            <div className="bg-white with-source">
                <div className="house__all-info-block__header">
                    <h2>Lien Information</h2>
                </div>
                <Table columns={LIEN_INFO_COLUMNS}>
                    {liensWithDates.map((el: tAssetLienMoment, id: number) => {
                        return <LienInfoRow key={`lien-info-${id}`} {...el} />;
                    })}
                </Table>
                <TableSourceRow source={EDMUNDS_ASSOC} />
            </div>
        </div>
    );
};

const NavigateBack = ({ navigate, slug }: { navigate: NavigateFunction; slug?: string }) => {
    const destination = slug != null && slug != '' ? `/municipality/${slug}` : '/';
    return <BackButton text="Back" onClick={() => navigate(destination)} />;
};

const makeLoadingSkeleton = (
    house: tHouseFull,
    navigate: NavigateFunction,
    property_id: string | undefined,
    slug: string | undefined
) => {
    return (
        <>
            <div className="house__header">
                <div className="container">
                    <NavigateBack navigate={navigate} slug={slug} />
                </div>
            </div>
            <div className="app__page house">
                <div className="house__grid container">
                    <div className="house__grid_item">
                        <BaseCard isLoading={true} item={house} controls={[]} />
                    </div>
                    <div className="house__grid_item house__grid_item--map">
                        <Map
                            defaultZoom={15}
                            defaultCenter={{ lat: +house.lat, lng: +house.lng }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            items={[{ ...(house as any), slug: property_id }]}
                            activePin={house.id}
                            clickable={false}
                        />
                    </div>
                </div>
                <div className="house__loading-indicator">
                    <Loader />
                </div>
                <Footer />
            </div>
        </>
    );
};

export const PropertyDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { slug, property_id } = useParams();
    const token = null;

    const { property, loading } = useAppSelector(state => state.propertyDetailsReducer);
    const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
    const [saleInfo, setSaleInfo] = useState<tSaleInfo | null>(null);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const {
        id,
        owner,
        block,
        lot,
        qual,
        house_class,
        district,
        city_state,
        year_built,
        house_style,
        sale_information,
        hash,
        history,
        make_payment_url,
        is_verified,
        is_owner,
        attachments,
        address,
        land_description,
        token_id,
        zone,
        zone_pdf_url,
    } = property;
    useEffect(() => {
        if (slug) {
            dispatch(getMunicipalityAsync(slug));
        }
    }, [slug]);
    useEffect(() => {
        if (!property_id || property_id === null || property_id === 'null') {
            navigate(R.MAIN);
        } else {
            dispatch(clearPropertyDetails());
            dispatch(getPropertyDetailsAsync(property_id as string));
        }
    }, [property_id]);

    const [lastTaxPaymentAmount, lastTaxPaymentDate] =
        getLastTaxPaymentAndDateFromTaxCollectionRecords(property);

    // Determining if a property has a lien is done here and above in TwoColumnAdditionalDetails
    // These should be standardized once the Property Details page has stabilized.
    let hasLien = false;
    if (property.tax_collection_records && property.tax_collection_records.length > 0) {
        hasLien = property.tax_collection_records.some(
            (record: tAssetTaxCollectionRecord) => record.has_lien === true
        );
    }

    if (loading) {
        return makeLoadingSkeleton(property, navigate, property_id, slug);
    }

    return (
        <>
            <div className="house__header">
                <div className="container">
                    <NavigateBack navigate={navigate} slug={slug} />
                </div>
            </div>
            <div className="app__page house">
                <div className="house__grid container">
                    <div className="house__grid_item">
                        <BaseCard
                            item={property}
                            controls={token && is_verified && is_owner ? MENU : []}
                            tokenIdLink={makeSnowtraceTestnetTransactionAddress(hash)}
                            tokenId={token_id}
                            displayLienBadge={hasLien}
                        />
                    </div>
                    <div className="house__grid_item house__grid_item--map">
                        <Map
                            defaultZoom={15}
                            defaultCenter={{ lat: +property.lat, lng: +property.lng }}
                            items={[{ ...property, slug: property_id }]}
                            activePin={id}
                            clickable={false}
                        />
                    </div>
                </div>
                <div className="house__grid house__grid--nine container">
                    <div className="house__grid_item">
                        <InfoCard
                            title="Owner"
                            text={owner}
                            icon={<IconOwner />}
                            showTooltip={true}
                        />
                    </div>
                    <div className="house__grid_item">
                        <InfoCard title="Zone" text={zone} url={zone_pdf_url} icon={<IconPin />} />
                    </div>
                    <div className="house__grid_item">
                        <InfoCard title="Block" text={block} icon={<IconBlock />} />
                    </div>
                    <div className="house__grid_item">
                        <InfoCard title="Lot" text={lot} icon={<IconLot />} />
                    </div>
                    <div className="house__grid_item">
                        <InfoCard
                            title="Last Tax Payment Amount"
                            text={lastTaxPaymentAmount}
                            icon={<IconTax />}
                        />
                    </div>
                    <div className="house__grid_item">
                        <InfoCard
                            title="Last Tax Payment Date"
                            text={lastTaxPaymentDate}
                            icon={<IconCalendar />}
                        />
                    </div>
                </div>
                <div className="bg-violet">
                    <div className="house__grid house__grid--nine container">
                        <div className="house__grid_item">
                            <InfoCard title="Qual" text={qual} />
                        </div>
                        <div className="house__grid_item">
                            <InfoCard title="Class" text={house_class} />
                        </div>
                        <div className="house__grid_item">
                            <InfoCard title="District" text={district} />
                        </div>
                        <div className="house__grid_item house__grid_item--big">
                            <InfoCard title="Prop Loc" text={address} />
                        </div>
                        <div className="house__grid_item house__grid_item--big">
                            <InfoCard title="City State" text={city_state} />
                        </div>
                        <div className="house__grid_item">
                            <InfoCard title="Land Desc" text={land_description} />
                        </div>
                        <div className="house__grid_item">
                            <InfoCard title="Year Built" text={nullIfZero(year_built)} />
                        </div>
                        <div className="house__grid_item house__grid_item--small">
                            <InfoCard title="Style" text={house_style} />
                        </div>
                    </div>
                </div>
                <TwoColumnAdditionalDetails house={property} />
                <div className="house__all-info bg-grey">
                    <AssetTaxTable
                        make_payment_url={make_payment_url}
                        taxRecords={property.tax_collection_records}
                    />

                    <SaleInfo sale_information={sale_information} />
                    <LienInfo house={property} />

                    {makeAssetDocs(property)}

                    {attachments &&
                        attachments.length > 0 &&
                        attachments.map((el: tDocs) => {
                            return (
                                <div className="bg-transparent container" key={el.title}>
                                    <h2>{el.title} Docs</h2>
                                    {el.list && (
                                        <Docs
                                            id={el.id}
                                            list={el.list}
                                            dockey={el.key}
                                            disabled={{
                                                // value: false,
                                                value: token ? false : true,
                                                text: 'Please Log In to view',
                                            }}
                                        />
                                    )}
                                    {el.tabs && (
                                        <DocsTab
                                            id={el.id}
                                            data={el.tabs}
                                            dockey={el.key}
                                            disabled={{
                                                value: token && is_owner ? false : true,
                                                text: 'Private documents are only available to the owner',
                                                private: true,
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                </div>
                <Footer />
                <Modal
                    show={saleInfo !== null}
                    onClose={() => {
                        setSaleInfo(null);
                    }}
                >
                    {saleInfo ? <SaleModal saleInfo={saleInfo} /> : <></>}
                </Modal>
                <Modal
                    show={showHistory}
                    onClose={() => {
                        setShowHistory(false);
                    }}
                >
                    {showHistory ? <HistoryModal history={history} /> : <></>}
                </Modal>
                <Modal
                    width={500}
                    show={isOpenCalendar}
                    onClose={() => {
                        setIsOpenCalendar(false);
                    }}
                >
                    {isOpenCalendar ? <CalendarModal /> : <></>}
                </Modal>
            </div>
        </>
    );
};
