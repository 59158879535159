import { default as CloudscapeModal } from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Alert from '@cloudscape-design/components/alert';

import { UserAccounts } from 'src/store';
import { tUserUploadedPropertyDocument } from 'src/types';
import { getPropertyDetailsAsync } from 'src/store';
import { useAppDispatch } from 'src/hooks';
import { deleteUserUploadedPropertyDocument } from '../../api';

export const DeleteModal = ({
    visible,
    onDismiss,
    user,
    item,
    setAlert,
}: {
    visible: boolean;
    onDismiss: () => void;
    user?: UserAccounts;
    item?: tUserUploadedPropertyDocument;
    setAlert: (alertMessage?: string) => void;
}) => {
    const dispatch = useAppDispatch();
    const filename = item != null ? item.file_name : '';

    const deleteButtonDisabled = item == null || user == null;

    const deleteFunction = async () => {
        if (item != null && user != null) {
            try {
                await deleteUserUploadedPropertyDocument(
                    user.thirdwebAccount,
                    item.property_id,
                    item.document_id
                );
                dispatch(getPropertyDetailsAsync(item.property_id));
                onDismiss();
            } catch (e) {
                console.error('Failed to delete document', e);
                setAlert('Failed to delete document');
                onDismiss();
            }
        }
    };

    return (
        <CloudscapeModal
            onDismiss={() => onDismiss()}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={deleteButtonDisabled}
                            variant="primary"
                            onClick={() => deleteFunction()}
                        >
                            Delete
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Delete '${filename}'`}
        >
            <Alert statusIconAriaLabel="Info">
                Deleting this file will remove it from our database and may make it permanently
                inaccesible on the blockchain.
            </Alert>
        </CloudscapeModal>
    );
};
