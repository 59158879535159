import { flexRender, Row } from '@tanstack/react-table';
import { NavigateFunction } from 'react-router-dom';
import { BalconyUser } from 'src/store';

const UserAdminTableRow = ({ row }: { row: Row<BalconyUser>; municipality_slug?: string }) => {
    return (
        <tr className="height-medium" key={row.id}>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
        </tr>
    );
};

export const userAdminTableRowRenderer = (
    row: Row<BalconyUser>,
    navigate: NavigateFunction,
    municipality_slug: string | undefined
) => (
    <UserAdminTableRow
        key={`user-admin-row-${row.id}`}
        row={row}
        municipality_slug={municipality_slug}
    />
);
