import { FC } from 'react';

interface IProps {
    columns: { header: string; key: string; type: string; class?: string }[];
}
export const TableHeader: FC<IProps> = ({ columns }) => {
    return (
        <thead>
            <tr>
                {columns.map(column => (
                    <th key={`table-column-${column.header}`} className={column.class}>
                        {column.header}
                    </th>
                ))}
            </tr>
        </thead>
    );
};
