import { UserConnectionStatus, UserReducer } from './types';

export const initialState: UserReducer = {
    user: undefined,
    showRegistrationModal: false,
    registeringUser: undefined,
    registrationErrors: {} as { [fieldName: string]: string | null },
    autoconnectStatus: UserConnectionStatus.Unstarted,
    balconyConnectStatus: UserConnectionStatus.Unstarted,
};
