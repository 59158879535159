import { InfoCard, Map, Loader } from 'src/components';
import { IconBlock, IconCalendar, IconLot, IconOwner, IconPin, IconTax } from 'src/icons';
import { tHouseFull, tHouseSmall } from 'src/types';
import { TwoColumnAdditionalDetails } from 'src/pages/PropertyDetails/PropertyDetails';
import { dollarFormat, makeDollarsCentsFormatter } from 'src/helpers';
import moment from 'moment';
import { DateTimeFormats } from 'src/helpers';
import './PropertySummary.scss';

const getLastTaxPaymentAndDateFromTaxCollectionRecords = (house: tHouseFull): [string, string] => {
    const defaultReturn: [string, string] = ['N/A', 'N/A'];
    if (!house.tax_collection_records || house.tax_collection_records.length === 0) {
        return defaultReturn;
    }

    const recordsWithMoments = house.tax_collection_records
        .filter(record => {
            const balanceBilled = record.adjusted_billed_usd + record.original_billed_usd;
            return record.balance_usd < balanceBilled;
        })
        .map(record => {
            const dueDateMoment = moment(record.due_date).utc();
            return { ...record, dueDateMoment };
        });

    recordsWithMoments.sort((a, b) => {
        if (a.dueDateMoment < b.dueDateMoment) {
            return 1;
        }
        if (a.dueDateMoment > b.dueDateMoment) {
            return -1;
        }
        return 0;
    });

    if (recordsWithMoments.length < 1) {
        return defaultReturn;
    }

    const latestPayment = recordsWithMoments[0];

    const paidAmount =
        latestPayment.adjusted_billed_usd +
        latestPayment.original_billed_usd -
        latestPayment.balance_usd;

    const lastAmount = dollarFormat(paidAmount, makeDollarsCentsFormatter());
    const lastDate = latestPayment.dueDateMoment.format(DateTimeFormats.MMDDYY);

    return [`$${lastAmount}`, lastDate];
};

export const PropertySummary = ({
    property,
    loading,
}: {
    property: tHouseFull;
    loading: boolean;
}) => {
    if (loading) {
        return (
            <div className="property-ledger-loader">
                <div className="ledger-loader">
                    <Loader />
                </div>
            </div>
        );
    }

    console.log(property);

    const [lastTaxPaymentAmount, lastTaxPaymentDate] =
        getLastTaxPaymentAndDateFromTaxCollectionRecords(property);

    const {
        id,
        owner,
        block,
        lot,
        qual,
        house_class,
        city_state,
        year_built,
        zone,
        zone_pdf_url,
        address,
        land_description,
        external_photo,
        style,
    } = property;

    const propertyForMap: tHouseSmall = {
        id: id?.toString() || '',
        address: address || '',
        block: block || '',
        lot: lot || '',
        lat: property.lat,
        lng: property.lng,
        owner: owner || '',
        estate_type: property.estate_type || '',
        hash: property.hash,
        slug: id?.toString() || '',
        recent_activity: property.recent_activity,
        is_verified: property.is_verified,
        vacant: property.vacant || false,
    };

    return (
        <div className="property-summary">
            <div>
                <div className="property-summary__grid property-summary__grid--nine">
                    <div className="property-summary__grid-item">
                        <InfoCard title="Qual" text={qual} />
                    </div>
                    <div className="property-summary__grid-item">
                        <InfoCard title="Class" text={house_class} />
                    </div>
                    <div className="property-summary__grid-item">
                        <InfoCard title="Year Built" text={year_built} />
                    </div>
                    <div className="property-summary__grid-item">
                        <InfoCard title="Style" text={style} />
                    </div>
                    <div className="property-summary__grid-item">
                        <InfoCard title="Prop Loc" text={address} />
                    </div>
                    <div className="property-summary__grid-item">
                        <InfoCard title="City State" text={city_state} />
                    </div>
                    <div className="property-summary__grid-item">
                        <InfoCard title="Land Desc" text={land_description} />
                    </div>
                </div>
            </div>

            <div className="property-summary__grid container">
                <div className="property-summary__grid-item property-summary__grid-item--map">
                    <div className="property-summary__map-photo-container">
                        {external_photo && (
                            <div className="property-summary__photo">
                                <img
                                    src={`data:image/jpeg;base64,${external_photo}`}
                                    alt={`Property at ${address}`}
                                    className="property-summary__photo-img"
                                />
                            </div>
                        )}
                        <div className="property-summary__map">
                            <Map
                                defaultZoom={15}
                                defaultCenter={{ lat: +property.lat, lng: +property.lng }}
                                items={[propertyForMap]}
                                activePin={propertyForMap.id}
                                clickable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="property-summary__grid property-summary__grid--nine container">
                <div className="property-summary__grid-item">
                    <InfoCard title="Owner" text={owner} icon={<IconOwner />} showTooltip={true} />
                </div>
                <div className="property-summary__grid-item">
                    <InfoCard title="Zone" text={zone} url={zone_pdf_url} icon={<IconPin />} />
                </div>
                <div className="property-summary__grid-item">
                    <InfoCard title="Block" text={block} icon={<IconBlock />} />
                </div>
                <div className="property-summary__grid-item">
                    <InfoCard title="Lot" text={lot} icon={<IconLot />} />
                </div>
                <div className="property-summary__grid-item">
                    <InfoCard
                        title="Last Tax Payment Amount"
                        text={lastTaxPaymentAmount}
                        icon={<IconTax />}
                    />
                </div>
                <div className="property-summary__grid-item">
                    <InfoCard
                        title="Last Tax Payment Date"
                        text={lastTaxPaymentDate}
                        icon={<IconCalendar />}
                    />
                </div>
            </div>

            <TwoColumnAdditionalDetails house={property} />
        </div>
    );
};
