import { tSearchPagination, tSearchPaginationState } from 'src/types';

export const updateReducerPaginationState = (
    previousPaginationState: tSearchPaginationState,
    paginationUpdate?: tSearchPagination
): tSearchPaginationState => {
    const pageIndex =
        paginationUpdate?.pageIndex != null
            ? paginationUpdate.pageIndex
            : previousPaginationState.pageIndex;

    const pageSize =
        paginationUpdate?.pageSize != null
            ? paginationUpdate.pageSize
            : previousPaginationState.pageSize;

    return {
        pageIndex,
        pageSize,
    };
};
