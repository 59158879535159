import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { tListViewTableColumnDef } from 'src/components';
import { OrganizationTypes } from 'src/constants';
import { BalconyUser } from 'src/store';

export const getMunicipalityIdFromAdminUser = (user: BalconyUser): string | undefined => {
    if (user.organization_permissions == null) {
        return undefined;
    }

    if (user.organization_permissions.organization_type !== OrganizationTypes.MUNICIPAL) {
        return undefined;
    }

    return user.organization_permissions.organization_locality;
};

export const getOrganizationName = (cell: CellContext<BalconyUser, unknown>): string => {
    return cell.row.original.organization_permissions != null
        ? cell.row.original.organization_permissions.organization_name
        : '';
};

export const balconyUserColumnHelper = createColumnHelper<BalconyUser>();

export const userAdministrationTableColumns: tListViewTableColumnDef<BalconyUser>[] = [
    balconyUserColumnHelper.accessor('full_name', {
        id: 'user',
        header: () => 'User',
        cell: row => row.row.original.full_name,
    }),
    balconyUserColumnHelper.accessor('email_address', {
        id: 'email_address',
        header: () => 'Email',
        cell: row => row.getValue(),
    }),
    balconyUserColumnHelper.accessor('wallet_address', {
        id: 'wallet_address',
        header: () => 'Wallet Address',
        cell: row => row.getValue(),
    }),
    balconyUserColumnHelper.accessor('organization_permissions', {
        id: 'organization_permissions',
        header: () => 'Organization',
        cell: row => getOrganizationName(row),
    }),
];
