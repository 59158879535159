import { ReactNode, useEffect } from 'react';
import { useActiveAccount, useActiveWallet, useIsAutoConnecting } from 'thirdweb/react';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    logoutThirdwebUser,
    logThirdwebUserIntoBalcony,
    updateAutoconnectStatus,
    UserConnectionStatus,
} from 'src/store';

interface IAuthIntegratorProps {
    children: ReactNode;
}

export const AuthIntegrator = ({ children }: IAuthIntegratorProps) => {
    const activeAccount = useActiveAccount();
    const activeWallet = useActiveWallet();
    const isAutoConnecting = useIsAutoConnecting();
    const { user, showRegistrationModal, balconyConnectStatus } = useAppSelector(
        state => state.userReducer
    );
    const thirdwebAccount = user ? user.thirdwebAccount : undefined;
    const dispatch = useAppDispatch();

    // When a Thirdweb connect button is first placed on a page, it will try to
    // `autoconnect`, or restore a previous session. During that time, we cannot
    // know if a user is logged in or not.
    // This hook propagates updates in `isAutoConnecting` to our UserReducer.
    // That reducer action will move the UserConnectionStatus for the
    // `autoconnectStatus` field on the UserReducer state from Unstarted ->
    // Started -> Finished
    // The `autoconnectStatus` field is used in the `AuthedPage` page to determine
    // if it should allow a user to see a page, require they log in,
    // or show a loading spinner.
    useEffect(() => {
        dispatch(updateAutoconnectStatus(isAutoConnecting));
    }, [isAutoConnecting]);

    // If we have an activeAccount and activeWallet, but not a thirdwebAccount in the state,
    // we should log in via Balcony's API. If that fails, we will show the user a registration
    // modal.
    const userLoggedInToThirdweb = activeAccount != null && activeWallet != null;
    const userLoggedInToBalcony = thirdwebAccount != null;
    if (userLoggedInToThirdweb && !userLoggedInToBalcony) {
        if (!showRegistrationModal && balconyConnectStatus === UserConnectionStatus.Unstarted) {
            dispatch(logThirdwebUserIntoBalcony(activeAccount, activeWallet));
        }
    }

    // Once thirdweb has been disconnected, remove the active user from the application state.
    const userHasLoggedOutOfThirdweb = activeAccount != null && activeWallet != null;
    if (!userHasLoggedOutOfThirdweb && userLoggedInToBalcony) {
        dispatch(logoutThirdwebUser());
    }

    return <>{children}</>;
};
