import { MainButton } from 'src/components';
import { Modal } from 'src/components';

import './UserAdministrationModal.scss';

export const UserAdministrationModalMainButton = ({
    buttonText,
    disabled,
    onClickFunction,
}: {
    buttonText: string;
    disabled: boolean;
    onClickFunction: () => void;
}) => {
    return (
        <MainButton
            className="user-administration-add"
            buttonType={'primary'}
            disabled={disabled}
            onClick={() => onClickFunction()}
        >
            {buttonText}
        </MainButton>
    );
};

export const UserAdministrationModalCancelButton = ({
    onClickFunction,
}: {
    onClickFunction: () => void;
}) => {
    return (
        <MainButton
            className="user-administration-cancel"
            buttonType={'primary'}
            onClick={onClickFunction}
        >
            Cancel
        </MainButton>
    );
};

interface IProps {
    showModal: boolean;
    title: string;
    description: string | JSX.Element;
    mainContent: string | JSX.Element;
    onClose: () => void;
    submitButton: JSX.Element;
    cancelButton: JSX.Element;
}

export const UserAdministrationModal = ({
    showModal,
    onClose,
    title,
    description,
    mainContent,
    cancelButton,
    submitButton,
}: IProps) => {
    return (
        <Modal className="user-administration-modal" show={showModal} onClose={() => onClose()}>
            {
                <div className="user-administration-modal-container">
                    <h2>{title}</h2>
                    <div className="user-administration-modal-description">{description}</div>
                    <div className="user-administration-modal-main">{mainContent}</div>
                    <div className="user-administration-modal-buttons">
                        {cancelButton}
                        {submitButton}
                    </div>
                </div>
            }
        </Modal>
    );
};
