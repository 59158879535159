import { Account, Wallet, WalletId } from 'thirdweb/wallets';

export type BalconyUser = {
    wallet_address: string;
    full_name: string;
    email_address: string;
    display_name: string;
    organization_permissions?: OrganizationPermissions;
    is_balcony_employee?: boolean;
};

export type OrganizationPermissions = {
    is_admin: boolean;
    organization_locality: string;
    organization_id: number;
    organization_member_id: number;
    organization_type: string;
    organization_name: string;
    access_levels?: MemberAccessLevels;
};

export type MemberAccessLevels = {
    [moduleId: string]: string;
};

export type UserAccounts = {
    thirdwebAccount: Account;
    thirdwebEmail: string;
    thirdwebWallet: Wallet<WalletId>;
    balconyUser: BalconyUser;
};

export type UserReducer = {
    user?: UserAccounts;
    showRegistrationModal: boolean;
    registrationErrors: { [fieldName: string]: string | null };
    registeringUser?: {
        account: Account;
        wallet: Wallet<WalletId>;
        email: string;
    };
    autoconnectStatus: UserConnectionStatus;
    balconyConnectStatus: UserConnectionStatus;
};

export enum UserConnectionStatus {
    Unstarted = 'UNSTARTED',
    Started = 'STARTED',
    Finished = 'FINISHED',
}
