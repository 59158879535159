import { tHouseFull } from 'src/types';
/*
 * Formatters for strings relating to money
 */
export const removeDollarSign = (s: string): string => {
    return s.replace('$', '');
};

export const makeDollarsCentsFormatter = () => {
    return new Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency' });
};

export const makeDollarFormatter = () => {
    return new Intl.NumberFormat('en-US', {
        currency: 'USD',
        style: 'currency',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
};

export const dollarFormat = (pennies: number, formatter?: Intl.NumberFormat): string => {
    if (formatter == null) {
        formatter = makeDollarsCentsFormatter();
    }

    return removeDollarSign(formatter.format(pennies / 100));
};

/*
 * Formatters for strings relating to addresses
 */
export const addressStringFormatter = (
    address: string | undefined,
    municipality: string | undefined,
    state: string | undefined,
    zip: string | undefined
): string => {
    const zipString = zip ? ` ${zip}` : '';
    const components = [address, municipality, state].filter(
        addressPart => addressPart != null && addressPart !== ''
    );
    const addressWithoutZip = components.join(', ');
    if (addressWithoutZip != '') {
        return addressWithoutZip + zipString;
    }
    return '';
};

export const propertyOwnerAddressFormatter = (house: tHouseFull): string => {
    return addressStringFormatter(
        house.owner_mailing_address,
        house.owner_town,
        house.owner_state,
        house.owner_zip
    );
};

export const propertyAddressFormatter = (house: tHouseFull): string => {
    return addressStringFormatter(house.address, house.municipality, house.state, house.zip);
};

export const fractionToPercent = (
    fraction: number,
    digitsOfPrecision: number,
    fallback = '-',
    postfix = '%'
): string => {
    try {
        return (fraction * 100).toFixed(digitsOfPrecision) + postfix;
    } catch (e) {
        console.error(
            `Encountered an error while trying to convert ${fraction} to a percentage with ${digitsOfPrecision} digits of precision.`
        );
        return fallback;
    }
};

export const formatFilesize = (sizeInBytes: number): string => {
    if (sizeInBytes === 0) return '0 Bytes';

    const units = ['Bytes', 'KB', 'MB', 'GB'];
    const k = 1000;

    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));

    const value = parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2));

    return `${value} ${units[i]}`;
};
export const formatMunicipalityWithState = (municipality: string, state?: string): string => {
    if (state) {
        return `${municipality}, ${state} Asset Registry`;
    }
    return `${municipality} Asset Registry`;
};
