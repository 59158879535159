import { FC } from 'react';

import './TableSourceRow.scss';

interface IProps {
    source: string;
}

export const TableSourceRow: FC<IProps> = ({ source }) => {
    return (
        <div className="table-source-row">
            <span>Source: {source}</span>
        </div>
    );
};
