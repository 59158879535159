import { BaseSelect } from 'src/components';
import { PropertySalesDateRanges } from 'src/store';

interface IProps {
    selectedDateRange: PropertySalesDateRanges;
    onDateRangeSelect: (newDateRanges: string) => void;
}

const DATE_RANGE_LABELS = {
    [PropertySalesDateRanges.LAST_WEEK]: 'Last Week',
    [PropertySalesDateRanges.LAST_MONTH]: 'Last Month',
    [PropertySalesDateRanges.LAST_TWO_MONTHS]: 'Last 2 Months',
    [PropertySalesDateRanges.LAST_SIX_MONTHS]: 'Last 6 Months',
    [PropertySalesDateRanges.YEAR_TO_DATE]: 'Year to Date',
};

export const PropertySalesDateRangeSelect = (props: IProps) => {
    return (
        <BaseSelect
            size="custom-l"
            additionText="Date Range"
            selectedValue={props.selectedDateRange}
            items={[
                {
                    id: PropertySalesDateRanges.LAST_WEEK,
                    name: DATE_RANGE_LABELS[PropertySalesDateRanges.LAST_WEEK],
                },
                {
                    id: PropertySalesDateRanges.LAST_MONTH,
                    name: DATE_RANGE_LABELS[PropertySalesDateRanges.LAST_MONTH],
                },
                {
                    id: PropertySalesDateRanges.LAST_TWO_MONTHS,
                    name: DATE_RANGE_LABELS[PropertySalesDateRanges.LAST_TWO_MONTHS],
                },
                {
                    id: PropertySalesDateRanges.LAST_SIX_MONTHS,
                    name: DATE_RANGE_LABELS[PropertySalesDateRanges.LAST_SIX_MONTHS],
                },
                {
                    id: PropertySalesDateRanges.YEAR_TO_DATE,
                    name: DATE_RANGE_LABELS[PropertySalesDateRanges.YEAR_TO_DATE],
                },
            ]}
            onClick={elementId => {
                props.onDateRangeSelect(elementId);
            }}
        />
    );
};
