import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Footer, LinkButton } from 'src/components';

import './VerifyEmailAddress.scss';
import { assetApiClient } from 'src/api';

export const VerifyEmailAddress = () => {
    const [searchParams] = useSearchParams();

    const verifyUser = async () => {
        try {
            await assetApiClient.post(
                '/email-verification/verify-email-address?code=' + searchParams.get('code')
            );
            setTimeout(() => window.open('/', '_self'), 3000);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        verifyUser();
    }, []);
    return (
        <div className="verify-email-address">
            <div className="verify-email-address__content">
                <h1>Thank you for verifying your email address.</h1>
                <div className="verify-email-address__message">
                    You will be redirected to the Asset Registry shortly.
                </div>
                <div className="verify-email-address__message">
                    Click
                    <LinkButton
                        text="here"
                        className="redirect-to-home"
                        onClick={e => {
                            e.stopPropagation();
                            window.open('/', '_self');
                        }}
                    />{' '}
                    if you are not redirected.
                </div>
            </div>
            <Footer />
        </div>
    );
};
