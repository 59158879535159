import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { OccupancyStatus } from 'src/constants';
import { updateReducerPaginationState } from 'src/helpers';
import { IAction } from 'src/interfaces';

import {
    GET_ALL_ADDRESSES,
    GET_ALL_HOUSES,
    CLEAR_QUERY,
    SET_LOADING,
    SET_PAGINATION,
    SET_QUERY,
    CLEAR_ALL_ADDRESSES,
} from './action-types';
import { initialState } from './initialState';
import { tHousesAction } from './types';

const housesPersistConfig = {
    key: 'housesState',
    storage: storage,
    whitelist: [],
};

const houses = (state = initialState, action: IAction<tHousesAction>) => {
    switch (action.type) {
        case SET_LOADING: {
            return {
                ...state,
                loading: action.payload?.loading,
            };
        }

        case SET_PAGINATION: {
            return {
                ...state,
                pagination: updateReducerPaginationState(
                    state.pagination,
                    action.payload?.pagination
                ),
            };
        }

        case GET_ALL_HOUSES: {
            return {
                ...state,
                query: action.payload?.query,
                houses:
                    action.payload?.query.skip === 0 || action.payload?.query.skip == null
                        ? action.payload?.data
                        : [...state.houses, ...(action.payload?.data || [])],
                count: action.payload?.count,
                has_more: action.payload?.has_more,
                sort: action.payload?.sort,
                loading: action.payload?.loading,
                pagination: updateReducerPaginationState(
                    state.pagination,
                    action.payload?.pagination
                ),
            };
        }
        case SET_QUERY: {
            return {
                ...state,
                query: action.payload?.data,
            };
        }

        case CLEAR_QUERY: {
            return {
                ...state,
                query: {
                    address: '',
                    owner_name: '',
                    block: '',
                    lot: '',
                    qualifier: '',
                    tax: '',
                    occupancy: OccupancyStatus.ALL,
                },
            };
        }

        case GET_ALL_ADDRESSES:
            return {
                ...state,
                addressesForAutocomplete: action.payload?.data,
            };

        case CLEAR_ALL_ADDRESSES:
            return {
                ...state,
                addressesForAutocomplete: [],
            };

        default:
            return state;
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const housesReducer = persistReducer<any>(housesPersistConfig, houses);
