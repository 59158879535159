import moment from 'moment';
import { PropertySalesDateRanges, tPropertySalesSearchOptions } from 'src/store/';

export enum DateTimeFormats {
    YYYYMMDD = 'YYYY-MM-DD',
    MMDDYY = 'MM/DD/YY',
    MMDDYYYY = 'MM/DD/YYYY',
    YYYY = 'YYYY',
    HHMMSS = 'HH:mm:ss',
}

export const formatPropertySalesDateRange = (
    range: PropertySalesDateRanges | string
): tPropertySalesSearchOptions => {
    switch (range) {
        case PropertySalesDateRanges.LAST_WEEK:
            return {
                start_date: moment().utc().subtract(7, 'days').format(DateTimeFormats.YYYYMMDD),
            };
        case PropertySalesDateRanges.LAST_MONTH:
            return {
                start_date: moment().utc().subtract(1, 'month').format(DateTimeFormats.YYYYMMDD),
            };
        case PropertySalesDateRanges.LAST_TWO_MONTHS:
            return {
                start_date: moment().utc().subtract(2, 'month').format(DateTimeFormats.YYYYMMDD),
            };
        case PropertySalesDateRanges.LAST_SIX_MONTHS:
            return {
                start_date: moment().utc().subtract(6, 'month').format(DateTimeFormats.YYYYMMDD),
            };
        case PropertySalesDateRanges.YEAR_TO_DATE:
            return { start_date: moment().utc().startOf('year').format(DateTimeFormats.YYYYMMDD) };
        default:
            return {};
    }
};
