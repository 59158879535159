import {
    clearPropertySales,
    getPropertySales,
    getPropertySalesMissingCOH,
    PropertySalesTabOption,
    UserAccounts,
} from 'src/store';
import { AppDispatch } from 'src/store/store';

import { userCanAccessCoHTab } from './auth';

export const dispatchLoadingCall = (
    dispatch: AppDispatch,
    tabOption: PropertySalesTabOption,
    slug: string,
    dateRange: string,
    user?: UserAccounts
) => {
    if (tabOption === PropertySalesTabOption.PROPERTY_SALES_MISSING_COH) {
        if (userCanAccessCoHTab(user, slug) && user != null) {
            dispatch(getPropertySalesMissingCOH(slug, user.thirdwebAccount, dateRange));
        } else {
            dispatch(clearPropertySales());
        }
    } else {
        dispatch(getPropertySales(slug, dateRange));
    }
};
