import './InfoLine.scss';
import { FC } from 'react';
import { LinkButton } from 'src/components';
interface IProps {
    title: string;
    text: string | number | null;
    empty?: boolean;
    url?: string;
    openInNewTab?: boolean;
}

export const InfoLine: FC<IProps> = ({
    title,
    url,
    text = '-',
    empty = false,
    openInNewTab = true,
}) => {
    const linkTarget = openInNewTab ? '_blank' : '_self';
    const rightCol =
        !empty && text && url ? (
            <LinkButton
                fontSize="medium"
                text={`${text}`}
                onClick={e => {
                    e.stopPropagation();
                    window.open(url, linkTarget);
                }}
            />
        ) : (
            <h4>{empty ? '' : text || '-'}</h4>
        );
    return (
        <div className="info-line">
            <h5>{title}</h5>
            {rightCol}
        </div>
    );
};
