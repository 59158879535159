import { copyText } from 'src/helpers';
import './InfoCard.scss';
import { FC, ReactNode } from 'react';
import { LinkButton } from '../Buttons';
import { Tooltip } from '../Tooltip';

interface IProps {
    icon?: ReactNode;
    title: string;
    text: string | null;
    reversed?: boolean;
    copy?: string;
    url?: string;
    showTooltip?: boolean;
}

const InfoCardH3 = ({ text, copy }: { text: string | null; copy?: string }) => {
    return (
        <h3 className={copy && 'clickable'} onClick={() => copy && copyText(copy)}>
            {text || '-'}
        </h3>
    );
};

const InfoCardLinkButton = ({ text, url }: { url: string; text: string }) => {
    return (
        <LinkButton
            text={text}
            fontSize="large"
            onClick={e => {
                e.stopPropagation();
                window.open(url, '_blank');
            }}
        />
    );
};

export const InfoCard: FC<IProps> = ({ title, text, icon, reversed, copy, url, showTooltip }) => {
    const infoBaseElement =
        url && text ? (
            <InfoCardLinkButton url={url} text={text} />
        ) : (
            <InfoCardH3 text={text} copy={copy} />
        );

    const infoElement =
        showTooltip && text !== null && text !== '' ? (
            <Tooltip text={text}>{infoBaseElement}</Tooltip>
        ) : (
            infoBaseElement
        );

    return (
        <div className="info-card">
            {icon && <div className="info-card__icon">{icon}</div>}
            <div
                className="info-card__content"
                style={{ flexDirection: reversed ? 'column-reverse' : 'column' }}
            >
                <h4>{title}</h4>
                {infoElement}
            </div>
        </div>
    );
};
