// DEPRECATED
export const makeArmadaDigitalOceanTokenAddress = (
    contractAddress?: string | null,
    tokenId?: number
): string | undefined => {
    if (contractAddress == null || tokenId == null) {
        return undefined;
    }

    return `https://armada-subnet.nyc3.digitaloceanspaces.com/asset-registry/${contractAddress}/${tokenId}.json`;
};

export const makeSnowtraceTestnetAddress = (contractAddress?: string): string | undefined => {
    if (contractAddress == null) {
        return undefined;
    }
    return `https://testnet.snowtrace.io/address/${contractAddress}`;
};

export const makeSnowtraceTestnetTransactionAddress = (hash?: string): string | undefined => {
    if (hash == null) {
        return undefined;
    }
    return `https://testnet.snowtrace.io/tx/${hash}`;
};

// DEPRECATED
export const makeSnowtraceTestnetTokenAddress = (
    contractAddress?: string | null,
    tokenId?: number,
    chainId?: number
): string | undefined => {
    if (contractAddress == null || tokenId == null) {
        return undefined;
    }
    let url = `https://testnet.snowtrace.io/nft/${contractAddress}/${tokenId}`;
    if (chainId != null) {
        url = `${url}?chain=${chainId}`;
    }

    return url;
};

export const makeArmadaExplorerUrl = (hash: string, type: 'tx' | 'address'): string => {
    return `https://subnets-test.avax.network/armada/${type}/${hash}`;
};
