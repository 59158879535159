import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_SEARCH_URL } from 'src/constants';

export const SearchResultsRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(DEFAULT_SEARCH_URL);
    }, []);

    return <></>;
};
