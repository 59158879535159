import { ReactElement } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
    AccessorKeyColumnDef,
    Row,
    Table,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table';

import { BaseSelect, Loader } from 'src/components';
import { HOUSES_PAGE_SIZE } from 'src/constants';
import { IconArrow } from 'src/icons';
import { tSearchPagination } from 'src/types';

import './ListViewTable.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type tListViewTableColumnDef<R> = AccessorKeyColumnDef<R, any>;

interface IProps<R, Q> {
    municipality_slug?: string;
    items: R[];
    loading: boolean;
    pageIndex: number;
    pageSize: number;
    updatePagination: (paginationUpdate: tSearchPagination) => void;
    resultsCount?: number;
    fetchMoreData: (queryData: Q, paginationUpdate?: tSearchPagination) => void;
    queryParameters: Q;
    columnsDefs: tListViewTableColumnDef<R>[];
    rowRenderer: ListViewTableRowRenderer<R>;
    noDataElement?: JSX.Element;
}

type ListViewTableRowRenderer<R> = (
    row: Row<R>,
    navigate: NavigateFunction,
    municipality_slug: string | undefined
) => JSX.Element;

const ListViewTableHeader = <R,>({ table }: { table: Table<R> }) => {
    return (
        <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <th key={header.id}>
                            {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

interface IBaseQuery {
    skip?: number;
    take?: number;
}

export const ListViewTable: <R, Q extends IBaseQuery>(
    p: IProps<R, Q>
) => ReactElement<IProps<R, Q>> = ({
    queryParameters,
    municipality_slug,
    items,
    loading,
    pageIndex,
    pageSize,
    updatePagination,
    resultsCount,
    fetchMoreData,
    columnsDefs,
    rowRenderer,
    noDataElement,
}) => {
    const navigate = useNavigate();
    const table = useReactTable({
        data: items,
        columns: columnsDefs,
        state: {
            pagination: { pageIndex: pageIndex, pageSize: pageSize },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const tableElement = (
        <>
            <div className="p-2 block max-w-full overflow-x-scroll table-elem tanstack-list-view">
                <table className="w-full ">
                    <ListViewTableHeader table={table} />
                    <tbody>
                        {!loading &&
                            table
                                .getRowModel()
                                .rows.map(row => rowRenderer(row, navigate, municipality_slug))}
                    </tbody>
                </table>
                {loading && (
                    <div className="tanstack-loader">
                        <Loader />
                    </div>
                )}
                {items.length === 0 && noDataElement != null && !loading && (
                    <div className="tanstack-no-data-element">{noDataElement}</div>
                )}
                <TableControlLine
                    updatePagination={updatePagination}
                    fetchMoreData={fetchMoreData}
                    table={table}
                    loading={loading}
                    resultsCount={resultsCount}
                    queryParameters={queryParameters}
                />
            </div>
        </>
    );

    return tableElement;
};

type TableControlLineProps<R, Q extends IBaseQuery> = {
    table: Table<R>;
    loading: boolean;
    resultsCount?: number;
    queryParameters: Q;
    updatePagination: (paginationUpdate: tSearchPagination) => void;
    fetchMoreData: (query: Q, paginationUpdate?: tSearchPagination) => void;
};

const TableControlLine = <R, Q extends IBaseQuery>({
    table,
    loading,
    resultsCount,
    queryParameters,
    updatePagination,
    fetchMoreData,
}: TableControlLineProps<R, Q>) => {
    const possiblePageSizes = [
        { id: '10', name: '10' },
        { id: '20', name: '20' },
        { id: '30', name: '30' },
        { id: '40', name: '40' },
        { id: '50', name: '50' },
    ];
    const allowedPageSizes = loading
        ? possiblePageSizes.filter(
              pageSizeOption => pageSizeOption.id == `${table.getState().pagination.pageSize}`
          )
        : possiblePageSizes;

    return (
        <div className="flex items-center gap-2 controls-line">
            <div className="page-size-select">
                <BaseSelect
                    selectedValue={`${table.getState().pagination.pageSize}`}
                    items={allowedPageSizes}
                    additionText={'Rows per page:'}
                    onClick={e => {
                        if (table.getState().pagination.pageSize != Number(e)) {
                            updatePagination({ pageIndex: 0, pageSize: Number(e) });
                        }
                    }}
                />
            </div>
            <span className="flex items-center gap-1 page-indicator">
                {currentPageIndicator(table, resultsCount)}
            </span>
            <button
                className="pagination-button"
                onClick={() =>
                    updatePagination({ pageIndex: table.getState().pagination.pageIndex - 1 })
                }
                disabled={!table.getCanPreviousPage() || loading}
            >
                <IconArrow />
            </button>
            <button
                className="pagination-button forward"
                onClick={() => {
                    if (needToFetchMoreData(table) && canFetchMoreData(table, resultsCount)) {
                        const skip = queryParameters.skip != null ? queryParameters.skip : 0;
                        const updatedQueryParameters = {
                            ...queryParameters,
                            skip: skip + HOUSES_PAGE_SIZE,
                        };
                        const updatedPaginationParameters = {
                            pageIndex: table.getState().pagination.pageIndex + 1,
                        };
                        fetchMoreData(updatedQueryParameters, updatedPaginationParameters);
                    } else {
                        updatePagination({ pageIndex: table.getState().pagination.pageIndex + 1 });
                    }
                }}
                disabled={
                    !(table.getCanNextPage() || canFetchMoreData(table, resultsCount)) || loading
                }
            >
                <IconArrow />
            </button>
        </div>
    );
};

export const canFetchMoreData = <R,>(table: Table<R>, count: number | undefined): boolean => {
    if (count != null && count <= table.getRowCount()) {
        return false;
    }
    const { pageIndex, pageSize } = table.getState().pagination;
    const totalNumberOfAvailableRecords = count != null ? count : table.getRowCount();
    const currentNumberOfTableRows = (pageIndex + 1) * pageSize;
    return currentNumberOfTableRows < totalNumberOfAvailableRecords;
};

const needToFetchMoreData = <R,>(table: Table<R>) => {
    const { pageIndex, pageSize } = table.getState().pagination;
    const nextPage = pageIndex + 1;
    const endOfNextPage = (nextPage + 1) * pageSize;
    return endOfNextPage > table.getRowCount();
};

const currentPageIndicator = <R,>(table: Table<R>, resultsCount?: number): string => {
    if (table.getRowCount() === 0 && (resultsCount == null || resultsCount === 0)) {
        return '';
    }
    const curPage = table.getState().pagination.pageIndex;
    const curSize = table.getState().pagination.pageSize;

    const start = curPage * curSize + 1;
    const rowCount = table.getRowCount();
    const calculatedEnd = (curPage + 1) * curSize;
    const end = rowCount < calculatedEnd ? rowCount : calculatedEnd;
    const total = resultsCount != null ? resultsCount : rowCount;
    return `${start}-${end} of ${total}`;
};
