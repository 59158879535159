import React, { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'src/constants';

export const PropertySalesPageRedirect = () => {
    const navigate = useNavigate();
    const { slug } = useParams();

    useEffect(() => {
        const slugForPath = slug != null ? slug : null;
        const path = generatePath(Routes.PROPERTY_SALES, { slug: slugForPath });
        navigate(path);
    }, []);

    return <></>;
};
