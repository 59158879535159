import { useEffect } from 'react';
import { initializeGoogleAnalytics } from 'src/helpers';

export const GoogleAnalyticsLoader = () => {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(window as any).GA_INITIALIZED) {
            initializeGoogleAnalytics();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).GA_INITIALIZED = true;
        }
    }, []);

    return <></>;
};
