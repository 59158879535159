import { useState, useEffect } from 'react';
import { CellContext } from '@tanstack/react-table';

import {
    ListViewTable,
    MainButton,
    tListViewTableColumnDef,
    userAdminTableRowRenderer,
} from 'src/components';
import {
    balconyUserColumnHelper,
    getMunicipalityIdFromAdminUser,
    userAdministrationTableColumns,
    userIsAdmin,
} from 'src/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { NotFound } from 'src/pages';
import {
    BalconyUser,
    MemberAccessLevels,
    UserReducer,
    getAllOrganizationMembersAsync,
    getEnabledModuleIdsAsync,
    getMunicipalityAsync,
    setUserAdminPagination,
    updateModulePermissionsAsync,
} from 'src/store';
import { tSearchPagination } from 'src/types';

import { ProductPermissionsModal } from './ProductPermissionsModal';
import { getActiveUserModuleCount } from './helpers';

import './ProductPermissions.scss';

export const ProductPermissions = () => {
    const dispatch = useAppDispatch();
    const { user }: UserReducer = useAppSelector(state => state.userReducer);
    const { municipality } = useAppSelector(state => state.municipalitiesReducer);
    const {
        enabledModules,
        loadingEnabledModules,
        loadingOrganizationMembers,
        organizationMembers,
        pagination,
    } = useAppSelector(state => state.userAdministrationReducer);
    const { pageIndex, pageSize } = pagination;
    const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] =
        useState<boolean>(false);
    const [selectedUserToManage, setSelectedUserToManage] = useState<BalconyUser | undefined>(
        undefined
    );
    useEffect(() => {
        if (!user) {
            return;
        }
        const userMunicipalityId = getMunicipalityIdFromAdminUser(user.balconyUser);
        if (municipality == null || municipality.municipality_id !== userMunicipalityId) {
            if (userMunicipalityId != null) {
                dispatch(getMunicipalityAsync(userMunicipalityId));
            }
        }

        dispatch(getAllOrganizationMembersAsync(user.thirdwebAccount));
        dispatch(getEnabledModuleIdsAsync(user.thirdwebAccount));
    }, [user]);

    if (user == null || !userIsAdmin(user)) {
        return <NotFound />;
    }

    const updatePaginationState = (paginationUpdate: tSearchPagination) => {
        dispatch(setUserAdminPagination(paginationUpdate));
    };

    const fetchMoreData = (query: Record<string, never>, paginationUpdate?: tSearchPagination) => {
        if (paginationUpdate != null) {
            dispatch(setUserAdminPagination(paginationUpdate));
        }
    };

    const makeManageButton = (cell: CellContext<BalconyUser, unknown>): JSX.Element => {
        const onClick = () => {
            const organizationMemberId =
                cell.row.original.organization_permissions?.organization_member_id;
            if (organizationMemberId != null) {
                setIsManagePermissionsModalOpen(true);
                setSelectedUserToManage(cell.row.original);
            }
        };

        return (
            <div className="product-permissions-manage-button">
                <MainButton buttonType="primary" onClick={onClick} disabled={loadingEnabledModules}>
                    Manage
                </MainButton>
            </div>
        );
    };

    const productPermissionsColumns: tListViewTableColumnDef<BalconyUser>[] = [
        ...userAdministrationTableColumns,
        balconyUserColumnHelper.accessor('display_name', {
            id: 'features_enabled',
            header: () => 'Features Enabled',
            cell: getActiveUserModuleCount,
        }),
        balconyUserColumnHelper.accessor('is_balcony_employee', {
            id: 'manage',
            header: () => 'Actions',
            cell: makeManageButton,
        }),
    ];

    const noUsersFound = (
        <div className="product-permissions-no-users-found">No users were found</div>
    );

    const table = (
        <ListViewTable
            loading={loadingOrganizationMembers}
            items={organizationMembers}
            pageIndex={pageIndex}
            pageSize={pageSize}
            updatePagination={updatePaginationState}
            resultsCount={organizationMembers.length}
            queryParameters={{}}
            fetchMoreData={fetchMoreData}
            columnsDefs={productPermissionsColumns}
            rowRenderer={userAdminTableRowRenderer}
            noDataElement={noUsersFound}
        />
    );

    return (
        <div className="product-permissions container">
            <div className="product-permissions-header-container">
                <div className="product-permissions-h1-container">
                    <h1>Product Permissions</h1>
                </div>
            </div>
            <div className="product-permissions__content">{table}</div>
            <ProductPermissionsModal
                enabledModuleIds={enabledModules}
                showModal={isManagePermissionsModalOpen && selectedUserToManage != null}
                cancelFunction={() => {
                    setIsManagePermissionsModalOpen(false);
                    setSelectedUserToManage(undefined);
                }}
                userToUpdate={selectedUserToManage}
                onClickFunction={(
                    organizationMemberId: number,
                    accessLevelsToUpdate: MemberAccessLevels,
                    modulePermissionsToRevoke: string[]
                ) => {
                    dispatch(
                        updateModulePermissionsAsync(
                            user.thirdwebAccount,
                            organizationMemberId,
                            accessLevelsToUpdate,
                            modulePermissionsToRevoke
                        )
                    );
                }}
            />
        </div>
    );
};
