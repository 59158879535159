import { ThirdwebClient, createThirdwebClient } from 'thirdweb';
import { inAppWallet } from 'thirdweb/wallets';

const thirdwebClient = createThirdwebClient({
    clientId: process.env.REACT_APP_THIRDWEB_CLIENT_ID as string,
});

export const getThirdWebClient = (): ThirdwebClient => {
    return thirdwebClient;
};

export const getSupportedWallets = () => {
    const wallets = [
        inAppWallet({
            auth: {
                options: ['email'],
            },
        }),
    ];
    return wallets;
};
