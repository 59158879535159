import { Dispatch } from 'redux';
import { Account } from 'thirdweb/wallets';

import { assetApiClient, makeSignedGetRequest } from 'src/api';
import { formatPropertySalesDateRange } from 'src/helpers';

import {
    tPropertySalesMissingCOHSummary,
    tPropertySalesSummary,
    tSearchPagination,
} from 'src/types';

import {
    CLEAR_PROPERTY_SALES,
    GET_ALL_PROPERTY_SALES_MISSING_COH,
    GET_ALL_PROPERTY_SALES,
    SET_PROPERTY_SALES_LOADING,
    SET_PROPERTY_SALES_PAGINATION,
    SET_PROPERTY_SALES_SEARCH_DATA,
} from './action-types';
import {
    PropertySalesDateRanges,
    tClearPropertySalesAction,
    tGetAllPropertySalesAction,
    tGetAllPropertySalesMissingCOHAction,
    tSetPropertySalesSetSearchDataAction,
    tSetPropertySalesLoadingAction,
    tSetPropertySalesPaginationAction,
    PropertySalesTabOption,
} from './types';

export const clearPropertySalesAction = (): tClearPropertySalesAction => {
    return {
        type: CLEAR_PROPERTY_SALES,
        payload: {},
    };
};

const setPropertySalesLoadingAction = (loading: boolean): tSetPropertySalesLoadingAction => {
    return {
        type: SET_PROPERTY_SALES_LOADING,
        payload: {
            loading,
        },
    };
};

const setPropertySalesDataMissingCOH = (
    propertySales: tPropertySalesMissingCOHSummary
): tGetAllPropertySalesMissingCOHAction => {
    return {
        type: GET_ALL_PROPERTY_SALES_MISSING_COH,
        payload: {
            loading: false,
            data: propertySales,
        },
    };
};

const setPropertySales = (propertySales: tPropertySalesSummary): tGetAllPropertySalesAction => {
    return {
        type: GET_ALL_PROPERTY_SALES,
        payload: {
            loading: false,
            data: propertySales,
        },
    };
};

export const changePropertySalesPagination = (
    paginationUpdate: tSearchPagination
): tSetPropertySalesPaginationAction => {
    return {
        type: SET_PROPERTY_SALES_PAGINATION,
        payload: {
            pagination: paginationUpdate,
        },
    };
};

export const changePropertySalesSearchData = (
    searchDateRangeOption?: PropertySalesDateRanges | string,
    propretySalesTabOption?: PropertySalesTabOption
): tSetPropertySalesSetSearchDataAction => {
    return {
        type: SET_PROPERTY_SALES_SEARCH_DATA,
        payload: {
            daterangeOption: searchDateRangeOption,
            tabOption: propretySalesTabOption,
        },
    };
};

export const clearPropertySales = () => async (dispatch: Dispatch) => {
    dispatch(clearPropertySalesAction());
};

export const setPropertySalesLoading = (loading: boolean) => async (dispatch: Dispatch) => {
    dispatch(setPropertySalesLoadingAction(loading));
};

export const makePropertySalesSearchQueryString = (
    dateRangeOption?: PropertySalesDateRanges | string
): string => {
    if (dateRangeOption == null) {
        return '';
    }
    const propertySalesSearchOptions = formatPropertySalesDateRange(dateRangeOption);
    const qs = new URLSearchParams(
        propertySalesSearchOptions != null ? propertySalesSearchOptions : {}
    );
    const qstring = qs.toString();
    return qstring != '' ? '?' + qstring : '';
};

export const getPropertySalesMissingCOH =
    (
        municipalityId: string,
        thirdwebAccount: Account,
        dateRangeOption?: PropertySalesDateRanges | string
    ) =>
    async (dispatch: Dispatch) => {
        dispatch(clearPropertySalesAction());
        dispatch(setPropertySalesLoadingAction(true));
        dispatch(changePropertySalesPagination({ pageIndex: 0 }));
        if (dateRangeOption != null) {
            dispatch(changePropertySalesSearchData(dateRangeOption));
        }

        const queryString = makePropertySalesSearchQueryString(dateRangeOption);

        try {
            const { data } = await makeSignedGetRequest(
                assetApiClient,
                thirdwebAccount,
                `/property-sales-missing-coh/${municipalityId}${queryString}`
            );
            dispatch(setPropertySalesDataMissingCOH(data));
        } catch (e) {
            dispatch(setPropertySalesLoadingAction(false));
        }
    };

export const getPropertySales =
    (municipalityId: string, dateRangeOption?: PropertySalesDateRanges | string) =>
    async (dispatch: Dispatch) => {
        dispatch(clearPropertySalesAction());
        dispatch(setPropertySalesLoadingAction(true));
        dispatch(changePropertySalesPagination({ pageIndex: 0 }));

        if (dateRangeOption != null) {
            dispatch(changePropertySalesSearchData(dateRangeOption));
        }

        const queryString = makePropertySalesSearchQueryString(dateRangeOption);
        try {
            const { data } = await assetApiClient(
                `/property-sales/${municipalityId}${queryString}`
            );
            dispatch(setPropertySales(data));
        } catch (e) {
            dispatch(setPropertySalesLoadingAction(false));
        }
    };
