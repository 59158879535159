export const SALE_INFO_COLUMNS = [
    {
        header: 'Date',
        key: 'date',
        type: 'text',
    },
    {
        header: 'Book',
        key: 'book',
        type: 'text',
    },
    {
        header: 'Page',
        key: 'page',
        type: 'text',
    },
    {
        header: 'Price',
        key: 'price',
        type: 'text',
    },
    {
        header: 'Grantee',
        key: 'grantee',
        type: 'text',
    },
];

export const LIEN_INFO_COLUMNS = [
    {
        header: 'Certificate Number',
        key: 'certificate_number',
        type: 'text',
    },
    {
        header: 'Sale Amount',
        key: 'sale_amount',
        type: 'text',
    },
    {
        header: 'Subsequent Charges',
        key: 'subsequent_charges',
        type: 'text',
    },
    {
        header: 'Sale Date',
        key: 'sale_date',
        type: 'text',
    },
    {
        header: 'Charge Types',
        key: 'charge_types',
        type: 'text',
    },
    {
        header: 'Lien Holder',
        key: 'lien_holder',
        type: 'text',
    },
    {
        header: 'Year In Sale',
        key: 'year_in_sale',
        type: 'text',
    },
];

export const TAX_HISTORY_COLUMNS = [
    {
        header: 'Year',
        key: 'year',
        type: 'text',
    },
    {
        header: 'Prop Loc',
        key: 'prop_loc',
        type: 'text',
    },
    {
        header: 'Land',
        key: 'land_assmnt',
        type: 'text',
    },
    {
        header: 'Imp',
        key: 'imp',
        type: 'text',
    },
    {
        header: 'Total',
        key: 'total',
        type: 'text',
    },
    {
        header: 'Exemption',
        key: 'exemption',
        type: 'title',
    },
    {
        header: 'Assessed',
        key: 'assessed',
        type: 'text',
    },
    {
        header: 'Prop Class',
        key: 'prop_class',
        type: 'text',
    },
    {
        header: 'Additional Lots',
        key: 'additional_lots',
        type: 'text',
    },
    {
        header: 'Deductions',
        key: 'deductions',
        type: 'text',
    },
];

export const TAXES_COLUMNS = [
    {
        header: 'Year',
        key: 'year',
        type: 'text',
    },
    {
        header: 'Due Date',
        key: 'due_date',
        type: 'text',
    },
    {
        header: 'Type',
        key: 'type',
        type: 'text',
    },
    {
        header: 'Billed',
        key: 'billed',
        type: 'text',
    },
    {
        header: 'Balance',
        key: 'balance',
        type: 'text',
    },
    {
        header: 'Interest',
        key: 'interest',
        type: 'title',
    },
    {
        header: 'Total Due',
        key: 'total_due',
        type: 'text',
    },
    {
        header: 'Status',
        key: 'status',
        type: 'text',
    },
];

export const ASSET_TAXES_COLUMNS = [
    {
        header: 'Year',
        key: 'year',
        type: 'text',
    },
    {
        header: 'Due Date',
        key: 'due_date',
        type: 'text',
    },
    {
        header: 'Type',
        key: 'type',
        type: 'text',
    },
    {
        header: 'Billed',
        key: 'billed',
        type: 'text',
    },
    {
        header: 'Adjusted Billed',
        key: 'adjusted-billed',
        type: 'text',
    },
    {
        header: 'Balance',
        key: 'balance',
        type: 'text',
    },
    {
        header: 'Interest',
        key: 'interest',
        type: 'text',
    },
    {
        header: 'Total Due',
        key: 'total-due',
        type: 'text',
    },
    {
        header: 'Status',
        key: 'status',
        type: 'text',
    },
];

export const MENU = [
    { title: 'Schedule Closing' },
    { title: 'List for Sale' },
    { title: 'Assign Permissions' },
    { title: 'Fractionalize', path: '/fractionalize' },
    { title: 'Build Virtual DD Room', key: 'soon' },
    { title: 'File Permit', key: 'soon' },
];
