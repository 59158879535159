import './MainInputDropdown.scss';

import { FC, useState, useRef } from 'react';
import { Loader, MainInput } from 'src/components';

import { useOnClickOutside } from 'src/hooks';
interface IProps {
    loading?: boolean;
    value: string;
    items: string[];
    label: string;
    placeholder: string;
    className?: string;
    disableTyping?: boolean;
    onSelect: (val: string) => void;
}
export const MainInputDropdown: FC<IProps> = ({
    loading = false,
    value,
    items,
    label,
    placeholder,
    disableTyping,
    className = '',
    onSelect,
}) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nodeRef = useRef<HTMLElement | any>();
    useOnClickOutside(nodeRef, () => setIsFocus(false));

    return (
        <div className={`main-input-dropdown ${className}`} ref={nodeRef}>
            <MainInput
                value={value}
                placeholder={placeholder}
                label={label}
                sufix={loading ? <Loader /> : ''}
                isFocus={isFocus}
                disableTyping={disableTyping}
                changeFocus={(focus: boolean) => setIsFocus(focus)}
                onChangeValue={(val: string) => onSelect(val)}
            />
            {isFocus && items.length > 0 && (
                <div className="main-input-dropdown__select">
                    <div className="main-input-dropdown__select_content">
                        {items.map((el, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="main-input-dropdown__select_content-item"
                                    onClick={() => {
                                        onSelect(el);
                                        setIsFocus(false);
                                    }}
                                >
                                    <h5>{el}</h5>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
