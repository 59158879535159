import { IAction } from '../../interfaces';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initialState } from './initialState';
import {
    ABANDON_REGISTRATION,
    BEGIN_REGISTRATION,
    FINISH_REGISTRATION,
    LOGIN_USER,
    LOGOUT_USER,
    SET_REGISTRATION_ERRORS,
    UPDATE_USER_AUTOCONNECT_STATUS,
    UPDATE_USER_BALCONY_CONNECT_STATUS,
} from './action-types';
import { UserConnectionStatus, UserReducer } from './types';

const userPersistConfig = {
    key: 'userState',
    storage: storage,
    whitelist: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const user = (state = initialState, { payload, type }: IAction<any>): UserReducer => {
    switch (type) {
        case BEGIN_REGISTRATION:
            return {
                ...state,
                user: undefined,
                showRegistrationModal: true,
                registeringUser: {
                    wallet: payload.registeringWallet,
                    account: payload.registeringUser,
                    email: payload.registeringEmail,
                },
            };
        case ABANDON_REGISTRATION:
            return {
                ...state,
                showRegistrationModal: false,
                registeringUser: undefined,
                autoconnectStatus: UserConnectionStatus.Unstarted,
                balconyConnectStatus: UserConnectionStatus.Unstarted,
            };
        case SET_REGISTRATION_ERRORS:
            return {
                ...state,
                registrationErrors: { ...state.registrationErrors, ...payload.errors },
            };
        case FINISH_REGISTRATION:
            return {
                ...state,
                registrationErrors: {},
                registeringUser: undefined,
                showRegistrationModal: false,
                user:
                    state.registeringUser != null
                        ? {
                              thirdwebWallet: state.registeringUser.wallet,
                              thirdwebAccount: state.registeringUser.account,
                              balconyUser: payload.balconyUser,
                              thirdwebEmail: state.registeringUser.email,
                          }
                        : undefined,
                balconyConnectStatus: UserConnectionStatus.Finished,
            };
        case LOGIN_USER: {
            return {
                ...state,
                user: {
                    balconyUser: payload.balconyUser,
                    thirdwebAccount: payload.thirdwebAccount,
                    thirdwebWallet: payload.thirdwebWallet,
                    thirdwebEmail: payload.thirdwebEmail,
                },
                balconyConnectStatus: UserConnectionStatus.Finished,
            };
        }
        case LOGOUT_USER: {
            return {
                ...state,
                user: undefined,
                autoconnectStatus: UserConnectionStatus.Finished,
                balconyConnectStatus: UserConnectionStatus.Unstarted,
            };
        }
        case UPDATE_USER_BALCONY_CONNECT_STATUS: {
            return {
                ...state,
                balconyConnectStatus: payload.balconyConnectStatus,
            };
        }

        case UPDATE_USER_AUTOCONNECT_STATUS: {
            if (
                payload.autoconnectStatus === true &&
                state.autoconnectStatus === UserConnectionStatus.Unstarted
            ) {
                return { ...state, autoconnectStatus: UserConnectionStatus.Started };
            }
            if (
                payload.autoconnectStatus === false &&
                state.autoconnectStatus === UserConnectionStatus.Started
            ) {
                return { ...state, autoconnectStatus: UserConnectionStatus.Finished };
            }
            return state;
        }
        default:
            return state;
    }
};

export const userReducer = persistReducer<UserReducer>(userPersistConfig, user);
