import { tDoc, tPropertyDocument } from 'src/types';

// URI decode the filename and use the path as the name.
// Use the extension as the mime type.
// Size checking is being bypassed for these files.
// This function is a great candidate for testing in CI
// once that's set up.
export const convertPropertyDocument = (doc: tPropertyDocument): tDoc => {
    const url = new URL(doc.url);
    const pathChunks = decodeURIComponent(url.pathname).split('/');
    const filename = pathChunks[pathChunks.length - 1];
    const filenameChunks = url.pathname.split('.');
    const extension = filenameChunks[filenameChunks.length - 1];
    return {
        name: filename,
        path: doc.url,
        size: '',
        mime: extension,
    };
};
