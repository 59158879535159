import { default as CloudscapeModal } from '@cloudscape-design/components/modal';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Input from '@cloudscape-design/components/input';
import FormField from '@cloudscape-design/components/form-field';
import { UserAccounts } from 'src/store';
import { tUserUploadedPropertyDocument } from 'src/types';
import { getPropertyDetailsAsync } from 'src/store';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/hooks';
import { editUserUploadedPropertyDocument } from '../../api';

export const EditModal = ({
    visible,
    onDismiss,
    user,
    item,
    setAlert,
}: {
    visible: boolean;
    onDismiss: () => void;
    user?: UserAccounts;
    item?: tUserUploadedPropertyDocument;
    setAlert: (alert?: string) => void;
}) => {
    const dispatch = useAppDispatch();
    const [filename, setFilename] = useState(item != null ? item.file_name : '');
    const filenameForTitle = item != null ? item.file_name : '';
    const saveButtonDisabled = item == null || user == null;

    useEffect(() => {
        setFilename(item != null ? item.file_name : '');
    }, [item]);

    const deleteFunction = async () => {
        if (item != null && user != null) {
            try {
                await editUserUploadedPropertyDocument(
                    user.thirdwebAccount,
                    item.property_id,
                    item.document_id,
                    filename
                );
                dispatch(getPropertyDetailsAsync(item.property_id));
                onDismiss();
            } catch (e) {
                console.error('Failed to update document metadata', e);
                setAlert('Failed to update document metadata');
                onDismiss();
            }
        }
    };

    return (
        <CloudscapeModal
            onDismiss={() => onDismiss()}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => onDismiss()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={saveButtonDisabled}
                            variant="primary"
                            onClick={() => deleteFunction()}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={`Edit '${filenameForTitle}'`}
        >
            <FormField
                constraintText="Ender a descriptive name for your file. This name will help identify and retrieve the file later."
                label="File Name - optional"
            >
                <Input value={filename} onChange={e => setFilename(e.detail.value)} />
            </FormField>
        </CloudscapeModal>
    );
};
