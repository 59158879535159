import { FC } from 'react';

interface IProps {
    color?: string;
    width?: number;
    height?: number;
}

export const IconCopy: FC<IProps> = ({ color = '#6A5ECC', width = 24, height = 24 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.90909 5.63636C2.40909 5.63636 2 6.04545 2 6.54545V20.1818C2 21.1818 2.81818 22 3.81818 22H17.4545C17.9545 22 18.3636 21.5909 18.3636 21.0909C18.3636 20.5909 17.9545 20.1818 17.4545 20.1818H4.72727C4.22727 20.1818 3.81818 19.7727 3.81818 19.2727V6.54545C3.81818 6.04545 3.40909 5.63636 2.90909 5.63636ZM20.1818 2H7.45455C6.45455 2 5.63636 2.81818 5.63636 3.81818V16.5455C5.63636 17.5455 6.45455 18.3636 7.45455 18.3636H20.1818C21.1818 18.3636 22 17.5455 22 16.5455V3.81818C22 2.81818 21.1818 2 20.1818 2ZM19.2727 16.5455H8.36364C7.86364 16.5455 7.45455 16.1364 7.45455 15.6364V4.72727C7.45455 4.22727 7.86364 3.81818 8.36364 3.81818H19.2727C19.7727 3.81818 20.1818 4.22727 20.1818 4.72727V15.6364C20.1818 16.1364 19.7727 16.5455 19.2727 16.5455Z"
                fill={color}
            />
        </svg>
    );
};
