import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { GET_MUNICIPALITIES, GET_MUNICIPALITY } from './action-types';
import { initialState } from './initialState';
import { tMunicipalitiesAction, tMunicipalitiesReducerState } from './types';

const municipalitiesPersistConfig = {
    key: 'municipalitiesState',
    storage: storage,
    whitelist: [],
};

const municipalities = (state = initialState, action: tMunicipalitiesAction) => {
    switch (action.type) {
        case GET_MUNICIPALITIES: {
            return {
                ...state,
                municipalities: action.payload.data,
            };
        }
        case GET_MUNICIPALITY: {
            return {
                ...state,
                municipality: action.payload.data,
            };
        }

        default:
            return state;
    }
};

export const municipalitiesReducer = persistReducer<
    tMunicipalitiesReducerState,
    tMunicipalitiesAction
>(municipalitiesPersistConfig, municipalities);
