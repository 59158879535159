import { FC } from 'react';
import { DateTimeFormats, dollarFormat } from 'src/helpers';
import { tAssetLienMoment } from 'src/types';

export const LienInfoRow: FC<tAssetLienMoment> = ({
    certificate_number,
    sale_amount_usd,
    subsequent_charges_usd,
    saleDateMoment,
    charge_types,
    lien_holder,
    year_in_sale,
}) => {
    return (
        <tr className="small">
            <td key={`cell-${certificate_number}-certificate-number`}>
                <h3 className="text">{certificate_number || '-'}</h3>
            </td>
            <td key={`cell-${certificate_number}-sale-amount`}>
                <h3 className="text">{dollarFormat(sale_amount_usd) || '-'}</h3>
            </td>
            <td key={`cell-${certificate_number}-subsequent-charges`}>
                <h3 className="text">{dollarFormat(subsequent_charges_usd) || '-'}</h3>
            </td>
            <td key={`cell-${certificate_number}-sale-date`}>
                <h3 className="text">{saleDateMoment.format(DateTimeFormats.MMDDYYYY)}</h3>
            </td>
            <td key={`cell-${certificate_number}-charge-types`}>
                <h3 className="text">{charge_types || '-'}</h3>
            </td>
            <td key={`cell-${certificate_number}-lien-holder`}>
                <h3 className="text">{lien_holder || '-'}</h3>
            </td>
            <td key={`cell-${certificate_number}-year-in-sale`}>
                <h3 className="text">{year_in_sale || '-'}</h3>
            </td>
        </tr>
    );
};
