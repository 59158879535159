import { NavigateFunction } from 'react-router-dom';
import { AccessorKeyColumnDef, createColumnHelper, flexRender, Row } from '@tanstack/react-table';

import { getMunicipalitySlugOrThrow } from 'src/helpers';

import { tHouseSmall } from 'src/types';

const columnHelper = createColumnHelper<tHouseSmall>();

export const searchResultsTableColumns: AccessorKeyColumnDef<tHouseSmall, string>[] = [
    columnHelper.accessor('address', {
        id: 'address',
        header: () => 'Address',
        cell: row => {
            return <h3>{row.getValue()}</h3>;
        },
    }),
    columnHelper.accessor('owner', {
        id: 'owner',
        header: () => 'Owner',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('block', {
        id: 'block',
        header: () => 'Block',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('lot', {
        id: 'lot',
        header: () => 'Lot',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('qualifier', {
        id: 'qualifier',
        header: () => 'Qualifier',
        cell: row => row.getValue(),
    }),
    columnHelper.accessor('estate_type', {
        id: 'class',
        header: () => 'Class',
        cell: row => row.getValue(),
    }),
];

const PropertyRow = ({
    row,
    municipality_slug,
    onClickFunction,
}: {
    row: Row<tHouseSmall>;
    municipality_slug?: string;
    onClickFunction: (s: string) => void;
}) => {
    const municipality = getMunicipalitySlugOrThrow(municipality_slug, row.original.municipality);
    return (
        <tr
            className="pointer height-medium"
            key={row.id}
            onClick={() => onClickFunction(`/municipality/${municipality}/${row.original.slug}`)}
        >
            {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
        </tr>
    );
};

export const propertyRowRenderer = (
    row: Row<tHouseSmall>,
    navigate: NavigateFunction,
    municipality_slug: string | undefined
) => (
    <PropertyRow
        key={`property-row-${row.id}`}
        onClickFunction={navigate}
        row={row}
        municipality_slug={municipality_slug}
    />
);
